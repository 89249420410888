@import "styles/utils";

#mobile-user-menu {
    &.active {
        .user-menu-background {
            display: block;
        }

        .user-menu-content {
            transform: translateX(0);
        }
    }

    .user-menu-background,
    .user-menu-content {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: 100%;
    }

    .user-menu-background {
        display: none;
        z-index: 3000;
        background-color: $black;
        opacity: 0.6;
    }

    .user-menu-content {
        display: flex;
        flex-direction: column;
        overflow: auto;
        z-index: 3100;
        margin-right: mobile(106);
        background-color: $white;
        transition: transform 0.45s;
        transform: translateX(-100%);
    }

    .list {
        padding: 0 mobile(40);

        & + .list {
            border-top: mobile(20) solid $gray-0;
        }

        &.header-quick-menu,
        &.menu-list {
            padding-bottom: mobile(40);
            border-bottom: mobile(1) solid $gray-1;
        }

        &.menu-list {
            padding-bottom: mobile(10);
        }
    }

    .top-area {
        display: flex;
        padding: mobile(80) $indent-mobile 0;
    }

    .user-info {
        display: flex;
        align-items: center;

        .rs-profile {
            flex-shrink: 0;
            margin-right: mobile(24);
        }

        p {
            margin-bottom: mobile(4);
            font-size: mobile(34);
            font-weight: $font-weight-medium;
            color: $gray-5;
        }

        ul,
        li {
            display: flex;
            align-items: center;
            font-size: mobile(24);
        }

        li {
            & + li {
                &:before {
                    content: "";
                    display: block;
                    width: mobile(2);
                    height: mobile(20);
                    margin: 0 mobile(12);
                    background-color: $gray-1;
                }
            }
        }

        .rs-icon {
            font-size: mobile(20);
        }
    }

    .quick-menu-list {
        display: flex;
        justify-content: space-between;
        margin-top: mobile(36);

        li {
            flex: 1;
            position: relative;
            text-align: center;

            & + li:not(.reviewer) {
                &:before {
                    content: "";
                    display: inline-block;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    width: mobile(1);
                    height: mobile(60);
                    background-color: $gray-1;
                    transform: translateY(-50%);
                }
            }
        }

        .image-wrapper {
            display: inline-flex;
            position: relative;
            height: mobile(44);
            margin-bottom: mobile(8);
        }

        img {
            width: mobile(43);
            height: 100%;
        }

        .rs-icon {
            font-size: mobile(44);
            color: $gray-5;
        }

        .menu-title {
            padding: 0 mobile(10);
            font-size: mobile(22);
            font-weight: $font-weight-medium;
            white-space: nowrap;
        }
    }

    .etc-info-items {
        display: flex;
        justify-content: space-between;
        margin-top: mobile(36);
    }

    .etc-info-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1;
        padding: $indent-mobile;
        border: mobile(1) solid $gray-1;
        border-radius: mobile(10);
        font-weight: $font-weight-medium;
        background-color: $gray-0;
        color: $gray-5;

        &.column {
            flex-direction: column;
            padding: mobile(36) mobile(20);

            > * {
                flex-shrink: 0;
            }

            .rs-button {
                width: 100%;
                margin-top: mobile(18);
            }
        }

        & + .etc-info-item {
            margin-left: mobile(14);
        }

        .points {
            font-size: mobile(32);
            font-weight: $font-weight-bold;
        }

        .rs-button {
            width: mobile(192);
        }

        .user-grade {
            display: flex;
            align-items: center;
        }

        .grade-text {
            margin-left: rem(8);
            font-size: mobile(32);
            font-weight: $font-weight-bold;
        }

        .common-grade-badge {
            height: auto;
        }

        .rs-icon {
            margin-left: mobile(14);
            padding-top: mobile(1);
            font-size: mobile(20);
        }
    }

    .menu-list {
        .menu {
            &-item {
                & + .menu-item {
                    border-top: mobile(1) solid $gray-1;
                }

                &:not(.active) {
                    .menu-content {
                        display: none;
                    }
                }
            }

            &-title {
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: mobile(100);
                font-size: mobile(32);
                font-weight: $font-weight-medium;

                .rs-icon {
                    font-size: mobile(24);
                }

                img {
                    width: mobile(36);
                    height: mobile(36);
                    margin-right: mobile(24);
                }
            }

            &-content {
                display: flex;
                flex-wrap: wrap;
                padding-bottom: mobile(15);
                font-size: mobile(28);
                font-weight: $font-weight-medium;
                line-height: mobile(86);

                a {
                    width: 50%;
                }
            }
        }
    }

    .other-links {
        margin-bottom: mobile(30);
        padding-top: mobile(50);

        .link-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: mobile(85);
            font-size: mobile(28);
            font-weight: $font-weight-medium;

            > div {
                display: flex;
                align-items: center;

                .rs-icon {
                    margin-right: mobile(28);
                    font-size: mobile(36);

                    &.icon_declaration {
                        font-size: mobile(35);
                    }
                }
            }
        }
    }

    .logout-area {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        padding-top: mobile(42);
        padding-bottom: calc(#{mobile(42)} + constants(safe-area-inset-bottom));
        padding-bottom: calc(#{mobile(42)} + env(safe-area-inset-bottom));
        background-color: $gray-0;

        .rs-button {
            width: mobile(210);
            border-radius: mobile(60);
            border-color: $gray-1;
        }

        .rs-icon {
            margin-right: mobile(20);
            font-size: mobile(32);
        }
    }
}
