@mixin bottom-tab-bar-style() {
    height: calc(#{$bottom-tab-bar-mobile} + constants(safe-area-inset-bottom));
    height: calc(#{$bottom-tab-bar-mobile} + env(safe-area-inset-bottom));
    padding-bottom: constants(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    background-color: $white;

    &.border {
        border-top: mobile(1) solid $gray-1;
    }

    &.shadow {
        box-shadow: 0 mobile(-3) mobile(6) 0 rgba($black, 0.16);
    }
}
