@mixin messenger-mobile-header {
    @include media("<mobile") {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        height: $header-height-mobile;
        padding: 0 $indent-mobile;
        border-bottom: mobile(1) solid $gray-1;
    }
}

@mixin messenger-mobile-header-title {
    @include media("<mobile") {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: mobile(140);
        right: mobile(140);
        bottom: 0;
        font-size: mobile(28);
        text-align: center;
        color: $gray-4;
    }
}
