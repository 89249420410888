@import "styles/utils";

#mobile-menu {
    &.active {
        // padding-bottom: calc(#{$bottom-tab-bar-mobile} + constants(safe-area-inset-bottom));
        // padding-bottom: calc(#{$bottom-tab-bar-mobile} + env(safe-area-inset-bottom));

        .menu-background {
            display: block;
        }

        .menu-content {
            transform: translateX(0);
        }
    }

    .menu-background,
    .menu-content {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        height: 100%;
    }

    .menu-background {
        display: none;
        z-index: 3000;
        background-color: $black;
        opacity: 0.6;
    }

    .menu-content {
        display: flex;
        flex-direction: column;
        overflow: auto;
        z-index: 3100;
        margin-right: mobile(106);
        background-color: $white;
        transition: transform 0.45s;
        transform: translateX(-100%);
    }

    .list {
        margin-bottom: mobile(220);
        padding: 0 mobile(40);

        .menu-item {
            display: flex;
            align-items: center;
            padding: 0 0 mobile(15);
            font-size: mobile(36);
            font-weight: $font-weight-medium;
            color: $gray-5;
        }

        a {
            display: flex;
            align-items: center;
            padding: mobile(15) 0;
            font-size: mobile(28);
        }

        &-wrapper {
            margin-bottom: mobile(40);
            padding-bottom: mobile(40);
            border-bottom: mobile(1) solid $gray-1;
        }
    }

    .top-area {
        align-self: center;
        padding: mobile(80) $indent-mobile mobile(54);
        text-align: center;

        .logo-area {
            display: flex;
            justify-content: center;
            align-items: center;
            width: mobile(150);
            height: mobile(150);
            min-height: mobile(150);
            margin: 0 auto;
            border-radius: 50%;
            box-shadow: 0 0 mobile(10) 0 rgba(230, 232, 237, 0.8);

            img {
                width: mobile(80);
            }
        }

        p {
            display: flex;
            align-items: center;
            margin: mobile(24) 0 mobile(30);
            font-size: mobile(32);
            font-weight: $font-weight-bold;
            color: $gray-5;

            .rs-icon {
                margin-left: mobile(12);
                font-size: mobile(40);
            }
        }

        a {
            padding: mobile(11) mobile(20);
            border: mobile(1) solid $gray-1;
            border-radius: mobile(6);
            font-size: mobile(24);
            font-weight: $font-weight-medium;
            color: $gray-5;
        }
    }
}
