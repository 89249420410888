/*----------------------*/
/*-----    Font    -----*/
/*----------------------*/

$font-size-xs: rem(12);
$font-size-s: rem(14);
$font-size-m: rem(16);
$font-size-l: rem(22);

$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
