@import "styles/utils";

.messenger-side-write-message-btn {
    padding: $messenger-indent;

    @include media("<mobile") {
        display: flex;
        justify-content: flex-end;
        position: relative;
        margin-right: $indent-mobile;
        padding: 0;
    }

    .rs-button {
        width: 100%;

        @include media("<mobile") {
            position: absolute;
            bottom: calc(#{mobile(80)} + constants(safe-area-inset-bottom));
            bottom: calc(#{mobile(80)} + env(safe-area-inset-bottom));
            width: mobile(104);
            border-radius: 50%;
            box-shadow: 0 mobile(4) mobile(6) 0 rgba(0, 0, 0, 0.2);
        }
    }
}
