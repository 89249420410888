@import "styles/utils";

#messenger-side-message-box-list {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    transition: margin-left 0.3s;

    .refresh-btn {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: rem(17) $indent-mobile;
        background-color: transparent;
        border: none;
        border-bottom: mobile(1) solid $gray-1;
        font-size: mobile(24);
        font-weight: $font-weight-medium;
        color: $gray-4;
        line-height: 1.5;

        .refresh-icon-wrapper {
            margin-right: mobile(10);

            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            border: mobile(1) solid $gray-1;
            width: mobile(58);
            height: mobile(58);
            font-size: mobile(24);
            color: $gray-4;
        }
    }
}
