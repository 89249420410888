@import "styles/utils";

#bottom-tab-bar {
    position: relative;
    z-index: 2000;

    @include media(">=mobile") {
        display: none;
    }

    #bottom-tab-bar-wrapper {
        @include bottom-tab-bar-style;
        display: flex;
        align-items: center;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
    }

    #bottom-tab-bar-padding-element {
        padding-top: calc(#{$bottom-tab-bar-mobile} + constants(safe-area-inset-bottom));
        padding-top: calc(#{$bottom-tab-bar-mobile} + env(safe-area-inset-bottom));
    }
}
