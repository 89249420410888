@import "styles/utils";

#refresh-confirm-notification {
    .refresh-btn {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-top: rem(8);
        font-weight: $font-weight-medium;
        color: $main-color;
        cursor: pointer;

        @include media("<mobile") {
            margin-top: mobile(15);
        }

        &:hover {
            p {
                border-bottom-color: $main-color;
            }
        }

        p {
            border-bottom: rem(1) solid transparent;

            @include media("<mobile") {
                border-bottom-width: mobile(1);
                border-bottom-color: $main-color;
            }
        }

        .rs-icon {
            margin-right: rem(4);

            @include media("<mobile") {
                margin-right: mobile(6);
            }
        }
    }
}
