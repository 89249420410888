.flex {
    display: flex;
}

.justify-center {
    justify-content: center;
}

.align-center {
    align-items: center;
}

.blue-font {
    color: $main-color !important;
}

.red-font {
    color: $red !important;
}

.yellow-font {
    color: $yellow !important;
}

.medium-font {
    font-weight: $font-weight-medium !important;
}

.bold-font {
    font-weight: $font-weight-bold !important;
}

.gray-2-font {
    color: $gray-2 !important;
}

.overflow-ellipse {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.web {
    @include media("<mobile") {
        display: none !important;
    }
}

.mobile {
    @include media(">=mobile") {
        display: none !important;
    }
}

.main-title {
    margin-top: rem(72);
    font-size: rem(32);
    font-weight: $font-weight-medium;

    @include media("<mobile") {
        margin-top: mobile(50);
        font-size: mobile(44);
        line-height: 1;
        color: $gray-5;
    }
}

.unread {
    position: relative;

    &:after {
        content: "";
        display: inline-block;
        position: absolute;
        top: 0;
        right: rem(-8);
        width: rem(6);
        height: rem(6);
        border-radius: 50%;
        background-color: $red;
    }
}

.unread-mark {
    position: relative;

    &:after {
        content: "";
        display: inline-block;
        background-image: url("../../assets/images/icons/icon_new.svg");
        position: absolute;
        top: rem(-12);
        right: rem(-12);
        width: rem(22);
        height: rem(22);
        border: rem(1) solid $white;
        border-radius: 50%;
        background-color: $red;

        @include media("<mobile") {
            top: mobile(-17);
            right: mobile(-17);
            width: mobile(34);
            height: mobile(34);
            border-width: mobile(1);
        }
    }
}

button.more-list-btn {
    margin: rem(45) auto;
    padding-left: rem(20);
    padding-right: rem(20);
    border-radius: rem(20);

    .icon_arrow_down {
        margin-left: rem(5);
        font-size: rem(10);
    }

    @include media("<mobile") {
        margin: mobile(95) auto;
        padding-left: mobile(25);
        padding-right: mobile(25);
        border-radius: mobile(30);

        .icon_arrow_down {
            margin-left: mobile(5);
            font-size: mobile(20);
        }
    }
}

.error-message {
    margin-top: rem(4);
    font-size: rem(12);
    color: $red !important;

    @include media("<mobile") {
        margin-top: mobile(6);
        font-size: mobile(24);
    }

    .rs-icon.icon_caution_circle {
        margin-right: rem(4);

        @include media("<mobile") {
            margin-right: mobile(8);
        }
    }
}
