@import "styles/utils";

.messenger-main-write-info {
    flex: 1;
    padding: $messenger-indent;

    @include media("<mobile") {
        padding: 0;
    }

    .title-box {
        @include messenger-mobile-header;

        h3 {
            margin-bottom: rem(4);
            font-size: rem(16);
            font-weight: $font-weight-medium;

            @include messenger-mobile-header-title;
        }

        .back-btn {
            @include only-mobile;
            @include btn-clear-style;
            @include media("<mobile") {
                font-size: mobile(36);
            }
        }
    }

    .select-item {
        display: flex;
        padding: rem(10) 0;
        border-bottom: rem(1) solid $gray-1;

        @include media("<mobile") {
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            margin: 0 $indent-mobile;
            padding: mobile(24) 0;
            border-bottom-width: mobile(1);
        }

        &.has-remove-list {
            @include media("<mobile") {
                flex-direction: column;
                align-items: stretch;
            }
        }

        .select-item-title {
            flex-shrink: 0;
            width: rem(90);
            font-size: rem(12);
            font-weight: $font-weight-medium;
            line-height: rem(32);

            @include media("<mobile") {
                width: auto;
                font-size: mobile(28);
                font-weight: $font-weight-normal;
                line-height: inherit;
            }
        }

        .select-item-content {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            flex: 1;
            width: rem(50);
            margin: rem(-3);

            @include media("<mobile") {
                flex: none;
                width: auto;
                margin: mobile(-6);
            }

            &.active {
                overflow: hidden;
            }
        }

        .remove-btn-list {
            display: flex;
            flex-wrap: wrap;
            overflow-x: hidden;
            overflow-y: auto;
            max-height: rem(76);

            @include media(">=wide-web") {
                max-width: rem(700);
            }

            @include media("<mobile") {
                max-width: mobile(560);
                max-height: mobile(136);
                margin-top: mobile(13);
            }

            & + .rs-button {
                width: rem(32);
                padding-bottom: 0;

                @include media("<mobile") {
                    width: mobile(58);
                }

                span {
                    display: none;
                }

                .rs-icon {
                    margin-top: 0;
                    margin-left: rem(1);

                    @include media("<mobile") {
                        margin-left: 0;
                    }
                }
            }
        }

        .rs-button {
            margin: rem(3);
            border-radius: rem(50);
            @include ellipsis;

            @include media("<mobile") {
                margin: mobile(6);
            }
        }

        [role="tooltip"] {
            z-index: 0;
        }

        .rs-bubble {
            border-color: $main-color;
            background-color: $main-color;
        }

        .add-btn {
            flex-shrink: 0;
            padding-left: rem(8);
            padding-right: rem(8);

            @include media("<mobile") {
                width: mobile(58);
                padding-left: 0;
                padding-right: 0;
                border-radius: 50%;
            }

            .rs-icon {
                margin-left: rem(4);
                font-size: rem(18);

                @include media("<mobile") {
                    margin-left: 0;
                    font-size: mobile(38);
                }
            }
        }

        .remove-btn {
            padding-left: rem(8);
            padding-right: rem(8);

            @include media("<mobile") {
                padding-left: mobile(20);
                padding-right: mobile(20);
            }

            .rs-icon {
                margin-left: rem(4);
                font-size: rem(8);
                color: $gray-2;

                @include media("<mobile") {
                    margin-left: mobile(10);
                    font-size: mobile(18);
                }
            }
        }
    }

    @include media("<mobile") {
    }
}
