@import "styles/utils";

.menuItems {
    @include media("<mobile") {
        margin-top: mobile(64);
    }
}

.menuItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $gray-0;

    @include media("<mobile") {
        padding: mobile(40);
        border-radius: mobile(20);
    }

    & + .menuItem {
        @include media("<mobile") {
            margin-top: mobile(20);
        }
    }
}

.menuTitle {
    font-weight: $font-weight-bold;
    color: $gray-5;

    @include media("<mobile") {
        font-size: mobile(32);
    }
}

.menuSubTitle {
    color: $gray-3;

    @include media("<mobile") {
        font-size: mobile(28);
    }
}

.menuIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $blue-darker;
    color: $white;

    @include media("<mobile") {
        width: mobile(92);
        height: mobile(92);
        margin-top: mobile(4);
        border-radius: mobile(20);
        font-size: mobile(52);
    }
}
