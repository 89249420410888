@import "styles/utils";

#messenger-main-message-list {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    position: relative;
    flex: 1;
    padding: $messenger-indent $messenger-indent rem(40);

    @include media("<mobile") {
        padding: 0 $indent-mobile mobile(60);
    }

    .get-list-btn {
        align-self: center;
        flex-shrink: 0;
        padding-left: rem(20);
        padding-right: rem(20);
        border-radius: rem(40);

        @include media("<mobile") {
            padding-left: mobile(40);
            padding-right: mobile(40);
            border-radius: mobile(60);
        }

        &.prev-btn {
            @include media("<mobile") {
                margin-top: mobile(42);
            }

            .rs-icon {
                margin-right: rem(4);

                @include media("<mobile") {
                    margin-right: mobile(10);
                }
            }
        }

        &.next-btn {
            margin-top: rem(20);

            @include media("<mobile") {
                margin: mobile(42) 0;
            }

            .rs-icon {
                margin-left: rem(4);

                @include media("<mobile") {
                    margin-left: mobile(10);
                }
            }
        }
    }
}
