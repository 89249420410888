@import "styles/utils";

.messenger-side-search-box {
    padding: rem(24) $messenger-indent $messenger-indent;

    @include media("<mobile") {
        padding: 0;
        border-bottom-width: mobile(1);
    }

    .title-box {
        display: flex;
        align-items: center;

        @include messenger-mobile-header;

        & + * {
            padding-top: rem(24);

            @include media("<mobile") {
                padding: $indent-mobile;
            }
        }

        .title-area {
            flex: 1;
            display: flex;

            @include media("<mobile") {
                order: 1;
            }
        }

        .refresh-btn {
            margin-left: rem(6);

            width: rem(27);
            height: rem(27);
            border: rem(1) solid $gray-1;
            border-radius: 50%;
            font-size: rem(13);
        }

        h3 {
            // flex: 1;
            font-size: rem(18);
            font-weight: $font-weight-medium;
            @include messenger-mobile-header-title;
        }

        button {
            @include btn-clear-style;
        }

        .back-btn {
            margin-right: rem(14);
            padding-bottom: 0;
            font-size: rem(20);

            @include media("<mobile") {
                margin-right: 0;
                font-size: mobile(36);
            }
        }

        .search-btn {
            font-size: rem(20);
            color: $gray-3;

            @include media("<mobile") {
                font-size: mobile(40);
                color: $gray-5;
            }
        }

        .close-btn {
            @include only-mobile;
            order: 2;
            font-size: mobile(32);
            color: $gray-2;
        }
    }

    .input-box {
        p {
            margin-top: rem(20);
            font-size: rem(14);
            color: $gray-2;

            @include media("<mobile") {
                margin-top: mobile(32);
                font-size: mobile(28);
            }
        }
    }
}

#messenger-search-box-brand-filter {
    @include media(">=mobile") {
        width: 100%;
    }

    @include media("<mobile") {
        color: $gray-5;
    }

    .rs-dropdown-title {
        .brand-name {
            display: flex;
            width: 100%;
        }

        span {
            @include ellipsis;
        }
    }

    .has-unread {
        @include unread-mark;
    }
}
