@import "styles/utils";

@keyframes newMessageAnimation {
    from {
        transform: translateY(-50%);
    }
    to {
        transform: inherit;
    }
}

#messenger-new-message {
    position: relative;

    .messenger-new-message-content {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: rem(8);
        left: rem(20);
        right: rem(20);
        z-index: 1;
        padding: rem(8);
        border-radius: rem(6);
        font-size: rem(12);
        font-weight: $font-weight-medium;
        text-align: center;
        white-space: pre-wrap;
        background-color: $white;
        animation: newMessageAnimation 0.2s linear;

        @include media("<mobile") {
            flex-direction: column;
            top: mobile(24);
            left: $indent-mobile;
            right: $indent-mobile;
            padding: mobile(28);
            border-radius: mobile(20);
            font-size: mobile(26);
        }
    }

    .common-emoji {
        margin-right: rem(4);

        @include media("<mobile") {
            margin-right: mobile(10);
        }
    }

    .brand-name {
        @include ellipsis;
        max-width: rem(160);

        @include media("<mobile") {
            max-width: mobile(300);
        }
    }

    p {
        line-height: 1.54;
    }
}
