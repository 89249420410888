@import "styles/utils";

.global-header {
    position: relative;
    background-color: $white;

    @include media(">=mobile") {
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        z-index: 100;
        height: $header-height;
        border-bottom: rem(1) solid $gray-1;
    }

    @include media("<mobile") {
        padding-top: $header-height-mobile;
    }

    .header-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include wide-web-width-limit($width-xl);

        @include media(">=mobile") {
            width: 100%;
        }

        @include media("<mobile") {
            height: 100%;
            padding: 0 $indent-mobile;
        }
    }

    .user-type-badge {
        margin-left: rem(6);

        @include media("<mobile") {
            margin-left: mobile(6);
        }
    }

    .new-mark {
        font-weight: $font-weight-bold;
        color: $orange-dark;
    }

    .top-menu {
        background-color: $white;
        border-bottom: rem(1) solid $gray-1;

        @include media(">=mobile") {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: $header-top-height;
        }

        @include media("<mobile") {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            z-index: $header-z-index;
            height: $header-height-mobile;
            border-bottom-width: mobile(1);
        }

        .business-page-template & {
            @include media("<mobile") {
                border-bottom-color: transparent;
            }
        }
    }

    .bottom-menu-list {
        @include only-web;
        display: flex;
        align-items: center;
    }

    .bottom-menu-item {
        position: relative;
        font-size: rem(18);
        font-weight: $font-weight-bold;
        line-height: 1.5;
        cursor: pointer;

        & + .bottom-menu-item {
            margin-left: rem(32);
        }

        &:not(:hover) .sub-menu {
            display: none;
        }

        > a {
            display: flex;
            align-items: center;
            height: $header-bottom-height;
            white-space: nowrap;
        }

        .beta-tag {
            display: block;
            height: rem(19);
            margin-left: rem(3);
        }
    }

    .sub-menu {
        position: absolute;
        top: calc(100% - #{rem(1)});
        left: 0;
        padding: rem(12) 0;
        border-radius: rem(6);
        font-size: rem(14);
        font-weight: $font-weight-medium;
        background-color: $white;
        white-space: nowrap;
        box-shadow: 0 0 rem(6) 0 rgba(0, 0, 0, 0.16);
        color: $gray-4;

        > div {
            padding: rem(9) rem(24);

            &:hover {
                color: $main-color;
            }
        }

        .rs-icon {
            margin-left: rem(4);
            color: $orange-dark;
        }
    }

    .bottom-menu {
        width: 100%;
    }

    .alarm-link {
        display: flex;
        padding: 0 rem(18) 0 rem(12);
        color: $gray-5;

        @include media("<mobile") {
            position: absolute;
            top: 50%;
            right: $indent-mobile;
            padding: 0;
            transform: translateY(-50%);
        }

        &.hasUnreadAlarm {
            .rs-icon {
                @include unread-mark(absolute);
            }
        }

        .rs-icon {
            padding: rem(4) 0;
            font-size: rem(22);

            @include media("<mobile") {
                font-size: mobile(44);
            }
        }
    }

    @include media(">=mobile") {
        .left-side {
            display: flex;
            align-items: center;
        }

        .logo-area {
            display: flex;
            align-items: center;
        }

        .reviewshare-logo {
            padding-top: rem(3);

            img {
                width: rem(190);
                height: rem(24);
            }
        }

        .right-side {
            display: flex;
            align-items: center;
            height: 100%;

            .btn-area {
                display: flex;
                align-items: center;

                > button {
                    width: rem(120);
                }
            }
        }

        .right-side-menu {
            display: flex;
            align-items: center;
            margin-right: rem(6);

            .right-menu-item {
                font-size: rem(14);
                font-weight: $font-weight-normal;
                line-height: 1.43;
                cursor: pointer;
                padding: rem(15) rem(10);
                border-radius: rem(30);

                button {
                    border: none;
                    border-bottom: rem(1) solid transparent;
                    background-color: transparent;
                    padding: 0;
                }

                &:hover {
                    button,
                    a {
                        border-bottom: rem(1) solid $gray-5;
                    }
                }

                &.blue-font {
                    &:hover {
                        button,
                        a {
                            border-bottom: rem(1) solid $main-color;
                        }
                    }
                }
            }
        }
    }

    @include media("<mobile") {
        .left-side {
            display: flex;
            justify-content: center;
            width: 100%;
        }

        .left-side-menu,
        .right-side {
            display: none;
        }

        .hamburger-menu-btn {
            position: absolute;
            top: 50%;
            left: $indent-mobile;
            font-size: mobile(44);
            transform: translateY(-50%);
            color: $gray-5;
        }

        .reviewshare-logo {
            img {
                display: block;
                width: mobile(250);
                height: mobile(30);
            }
        }

        .logo-area {
            display: flex;
            align-items: center;
        }
    }
}
