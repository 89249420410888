@import "styles/utils";

.public-page-template {
    .page-body {
        display: flex;
        flex-direction: column;
        flex: 1;
        position: relative;
        z-index: 0;
    }
}
