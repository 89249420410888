@mixin field-label-hidden() {
    .label {
        display: none;
    }
}

@mixin field-label-display() {
    .label {
        display: initial;
    }
}

@mixin btn-clear-style {
    padding: 0;
    border: none;
    border-color: transparent;
    font-size: inherit;
    white-space: pre-wrap;
    background-color: transparent;
    color: inherit;
}

@mixin input-addon-size-s {
    & ~ .rs-input-addon-before,
    & ~ .rs-input-addon-after,
    & ~ .rs-input-prefix,
    & ~ .rs-input-suffix {
        @include field-font-size("s");
        height: $field-height-s;
    }

    & ~ .rs-input-prefix {
        margin-right: rem(2);
        padding-left: $field-indent-s;
    }

    & ~ .rs-input-suffix {
        margin-left: rem(2);
    }

    & ~ div:last-child:not(.rs-input-addon-after):not(.rs-input-prefix) {
        padding-right: $field-indent-s;
    }

    & ~ .rs-input-buttons {
        .rs-input-search-btn {
            margin-left: $field-indent-s;
            font-size: rem(16);
        }

        .rs-input-delete-btn {
            margin-left: rem(4);
            font-size: rem(14);
        }
    }
}

@mixin input-addon-size-m {
    & ~ .rs-input-addon-before,
    & ~ .rs-input-addon-after,
    & ~ .rs-input-prefix,
    & ~ .rs-input-suffix {
        @include field-font-size("m");
        height: $field-height-m;
    }

    & ~ .rs-input-prefix {
        margin-right: rem(3);
        padding-left: $field-indent-m;
    }

    & ~ .rs-input-suffix {
        margin-left: rem(3);
    }

    & ~ div:last-child:not(.rs-input-addon-after):not(.rs-input-prefix) {
        padding-right: $field-indent-m;
    }

    & ~ .rs-input-buttons {
        .rs-input-search-btn {
            margin-left: $field-indent-m;
            font-size: rem(18);
        }

        .rs-input-delete-btn {
            margin-left: rem(6);
            font-size: rem(14);
        }
    }
}

@mixin input-addon-size-l {
    & ~ .rs-input-addon-before,
    & ~ .rs-input-addon-after,
    & ~ .rs-input-prefix,
    & ~ .rs-input-suffix {
        @include field-font-size("l");
        height: $field-height-l;
    }

    & ~ .rs-input-prefix {
        margin-right: rem(3);
        padding-left: $field-indent-l;
    }

    & ~ .rs-input-suffix {
        margin-left: rem(3);
    }

    & ~ div:last-child:not(.rs-input-addon-after):not(.rs-input-prefix) {
        padding-right: $field-indent-l;
    }

    & ~ .rs-input-buttons {
        .rs-input-search-btn {
            margin-left: $field-indent-l;
            font-size: rem(20);
        }

        .rs-input-delete-btn {
            margin-left: rem(9);
            font-size: rem(18);
        }
    }
}

@mixin input-addon-size-xl {
    & ~ .rs-input-addon-before,
    & ~ .rs-input-addon-after,
    & ~ .rs-input-prefix,
    & ~ .rs-input-suffix {
        @include field-font-size("xl");
        height: $field-height-xl;
    }

    & ~ .rs-input-prefix {
        margin-right: rem(3);
        padding-left: $field-indent-xl;
    }

    & ~ .rs-input-suffix {
        margin-left: rem(3);
    }

    & ~ div:last-child:not(.rs-input-addon-after):not(.rs-input-prefix) {
        padding-right: $field-indent-xl;
    }

    & ~ .rs-input-buttons {
        .rs-input-search-btn {
            margin-left: $field-indent-xl;
            font-size: rem(22);
        }

        .rs-input-delete-btn {
            margin-left: rem(12);
            font-size: rem(18);
        }
    }
}

@mixin input-addon-m-size-s {
    & ~ .rs-input-addon-before,
    & ~ .rs-input-addon-after,
    & ~ .rs-input-prefix,
    & ~ .rs-input-suffix {
        @include field-font-size("m-s");
        height: $field-height-m-s;
    }

    & ~ .rs-input-prefix {
        margin-right: mobile(3);
        padding-left: $field-indent-m-s;
    }

    & ~ .rs-input-suffix {
        margin-left: mobile(3);
    }

    & ~ div:last-child:not(.rs-input-addon-after):not(.rs-input-prefix) {
        padding-right: $field-indent-m-s;
    }

    & ~ .rs-input-buttons {
        .rs-input-search-btn {
            margin-left: $field-indent-m-s;
            font-size: mobile(36);
        }

        .rs-input-delete-btn {
            font-size: mobile(28);
        }
    }
}

@mixin input-addon-m-size-m {
    & ~ .rs-input-addon-before,
    & ~ .rs-input-addon-after,
    & ~ .rs-input-prefix,
    & ~ .rs-input-suffix {
        @include field-font-size("m-m");
        height: $field-height-m-m;
    }

    & ~ .rs-input-prefix {
        margin-right: mobile(4);
        padding-left: $field-indent-m-m;
    }

    & ~ .rs-input-suffix {
        margin-left: mobile(4);
    }

    & ~ div:last-child:not(.rs-input-addon-after):not(.rs-input-prefix) {
        padding-right: $field-indent-m-m;
    }

    & ~ .rs-input-buttons {
        .rs-input-search-btn {
            margin-left: $field-indent-m-m;
            font-size: mobile(36);
        }

        .rs-input-delete-btn {
            margin-left: mobile(12);
            font-size: mobile(28);
        }
    }
}

@mixin input-addon-m-size-l {
    & ~ .rs-input-addon-before,
    & ~ .rs-input-addon-after,
    & ~ .rs-input-prefix,
    & ~ .rs-input-suffix {
        @include field-font-size("m-l");
        height: $field-height-m-l;
    }

    & ~ .rs-input-prefix {
        margin-right: mobile(5);
        padding-left: $field-indent-m-l;
    }

    & ~ .rs-input-suffix {
        margin-left: mobile(5);
    }

    & ~ div:last-child:not(.rs-input-addon-after):not(.rs-input-prefix) {
        padding-right: $field-indent-m-l;
    }

    & ~ .rs-input-buttons {
        .rs-input-search-btn {
            margin-left: $field-indent-m-l;
            font-size: mobile(40);
        }

        .rs-input-delete-btn {
            font-size: mobile(32);
        }
    }
}

@mixin field-font-size($size: "m") {
    @if ($size == "s") {
        font-size: $field-font-size-s;
    }

    @if ($size == "m") {
        font-size: $field-font-size-m;
    }

    @if ($size == "l") {
        font-size: $field-font-size-l;
    }

    @if ($size == "xl") {
        font-size: $field-font-size-xl;
    }

    @if ($size == "m-s") {
        font-size: $field-font-size-m-s;
    }

    @if ($size == "m-m") {
        font-size: $field-font-size-m-m;
    }

    @if ($size == "m-l") {
        font-size: $field-font-size-m-l;
    }
}

@mixin field-size-base {
    @include field-indent-size("m");
    @include field-font-size("m");

    @include media("<mobile") {
        @include field-indent-size("m-m");
        @include field-font-size("m-m");
    }

    &.size-s {
        @include media(">=mobile") {
            @include field-indent-size("s");
            @include field-font-size("s");
        }
    }

    &.size-l {
        @include media(">=mobile") {
            @include field-indent-size("l");
            @include field-font-size("l");
        }
    }

    &.size-xl {
        @include media(">=mobile") {
            @include field-indent-size("xl");
            @include field-font-size("xl");
        }
    }

    &.w-size-s {
        @include media("<wide-web", ">=mobile") {
            @include field-indent-size("s");
            @include field-font-size("s");
        }
    }

    &.w-size-m {
        @include media("<wide-web", ">=mobile") {
            @include field-indent-size("m");
            @include field-font-size("m");
        }
    }

    &.w-size-l {
        @include media("<wide-web", ">=mobile") {
            @include field-indent-size("l");
            @include field-font-size("l");
        }
    }

    &.w-size-xl {
        @include media("<wide-web", ">=mobile") {
            @include field-indent-size("xl");
            @include field-font-size("xl");
        }
    }

    &.m-size-s {
        @include media("<mobile") {
            @include field-indent-size("m-s");
            @include field-font-size("m-s");
        }
    }

    &.m-size-l {
        @include media("<mobile") {
            @include field-indent-size("m-l");
            @include field-font-size("m-l");
        }
    }
}

@mixin field-height-size {
    height: $field-height-m;

    @include media("<mobile") {
        height: $field-height-m-m;
    }

    &.size-s {
        @include media(">=mobile") {
            height: $field-height-s;
        }
    }

    &.size-l {
        @include media(">=mobile") {
            height: $field-height-l;
        }
    }

    &.size-xl {
        @include media(">=mobile") {
            height: $field-height-xl;
        }
    }

    &.w-size-s {
        @include media("<wide-web", ">=mobile") {
            height: $field-height-s;
        }
    }

    &.w-size-m {
        @include media("<wide-web", ">=mobile") {
            height: $field-height-m;
        }
    }

    &.w-size-l {
        @include media("<wide-web", ">=mobile") {
            height: $field-height-l;
        }
    }

    &.w-size-xl {
        @include media("<wide-web", ">=mobile") {
            height: $field-height-xl;
        }
    }

    &.m-size-s {
        @include media("<mobile") {
            height: $field-height-m-s;
        }
    }

    &.m-size-l {
        @include media("<mobile") {
            height: $field-height-m-l;
        }
    }
}

@mixin field-indent-size($size: "m") {
    @if ($size == "s") {
        padding-left: $field-indent-s;
        padding-right: $field-indent-s;
    }

    @if ($size == "m") {
        padding-left: $field-indent-m;
        padding-right: $field-indent-m;
    }

    @if ($size == "l") {
        padding-left: $field-indent-l;
        padding-right: $field-indent-l;
    }

    @if ($size == "xl") {
        padding-left: $field-indent-xl;
        padding-right: $field-indent-xl;
    }

    @if ($size == "m-s") {
        padding-left: $field-indent-m-s;
        padding-right: $field-indent-m-s;
    }

    @if ($size == "m-m") {
        padding-left: $field-indent-m-m;
        padding-right: $field-indent-m-m;
    }

    @if ($size == "m-l") {
        padding-left: $field-indent-m-l;
        padding-right: $field-indent-m-l;
    }
}

@mixin field-addon-size {
    @include input-addon-size-m;

    @include media("<mobile") {
        @include input-addon-m-size-m;
    }

    &.size-s {
        @include media(">=mobile") {
            @include input-addon-size-s;
        }
    }

    &.size-l {
        @include media(">=mobile") {
            @include input-addon-size-l;
        }
    }

    &.size-xl {
        @include media(">=mobile") {
            @include input-addon-size-xl;
        }
    }

    &.w-size-s {
        @include media("<wide-web", ">=mobile") {
            @include input-addon-size-s;
        }
    }

    &.w-size-m {
        @include media("<wide-web", ">=mobile") {
            @include input-addon-size-m;
        }
    }

    &.w-size-l {
        @include media("<wide-web", ">=mobile") {
            @include input-addon-size-l;
        }
    }

    &.w-size-xl {
        @include media("<wide-web", ">=mobile") {
            @include input-addon-size-xl;
        }
    }

    &.m-size-s {
        @include media("<mobile") {
            @include input-addon-m-size-s;
        }
    }

    &.m-size-l {
        @include media("<mobile") {
            @include input-addon-m-size-l;
        }
    }
}

@mixin input-style {
    width: 100%;
    padding-bottom: rem(1);
    border: rem(1) solid $gray-1;
    border-radius: $field-border-radius;
    @include field-size-base;
    @include field-height-size;

    @include media("<mobile") {
        padding-top: 0;
        padding-bottom: 0;
        border-width: mobile(1);
        border-radius: $field-border-radius-m;
    }

    &:not(:disabled):focus {
        border-color: $gray-2;
    }

    &:disabled {
        color: $gray-2;
        background-color: $gray-0;
        opacity: 1 !important;
        -webkit-text-fill-color: $gray-2 !important;
    }
}

@mixin check-input {
    input {
        display: none;
    }

    input + label {
        .icon_checkbox_on,
        .icon_checkbox_fill,
        .toggle_on,
        .icon_radio_on {
            display: none;
        }

        .toggle_off {
            display: none;

            @include media("<mobile") {
                display: flex;
            }
        }
    }

    input:checked + label {
        .icon_checkbox_off,
        .icon_radio_off {
            display: none;
        }

        .icon_checkbox_on,
        .icon_checkbox_fill,
        .icon_radio_on {
            display: inline-block;
        }

        .toggle_off {
            display: none;

            @include media("<mobile") {
                display: none;
            }
        }

        .toggle_on {
            display: none;

            @include media("<mobile") {
                display: flex;
            }
        }
    }
}

@mixin textarea-style {
    display: block;
    padding-top: rem(8);
    padding-bottom: rem(8);
    border: rem(1) solid $gray-1;
    border-radius: $field-border-radius;
    @include field-size-base;

    @include media("<mobile") {
        padding-top: mobile(20);
        padding-bottom: mobile(20);
        border-width: mobile(1);
        border-radius: $field-border-radius-m;
    }

    &.size-s {
        @include media(">=mobile") {
            padding-top: rem(7);
            padding-bottom: rem(7);
        }
    }

    &.size-l {
        @include media(">=mobile") {
            padding-top: rem(13);
            padding-bottom: rem(13);
        }
    }

    &.size-xl {
        @include media(">=mobile") {
            padding-top: rem(17);
            padding-bottom: rem(17);
        }
    }

    &.w-size-s {
        @include media("<wide-web", ">=mobile") {
            padding-top: rem(7);
            padding-bottom: rem(7);
        }
    }

    &.w-size-m {
        @include media("<wide-web", ">=mobile") {
            padding-top: rem(8);
            padding-bottom: rem(8);
        }
    }

    &.w-size-l {
        @include media("<wide-web", ">=mobile") {
            padding-top: rem(13);
            padding-bottom: rem(13);
        }
    }

    &.w-size-xl {
        @include media("<wide-web", ">=mobile") {
            padding-top: rem(17);
            padding-bottom: rem(17);
        }
    }

    &.m-size-s {
        @include media("<mobile") {
            padding-top: mobile(17);
            padding-bottom: mobile(17);
        }
    }

    &.m-size-l {
        @include media("<mobile") {
            padding-top: mobile(23);
            padding-bottom: mobile(23);
        }
    }
}

@mixin checkbox-style {
    img {
        margin-top: rem(1);

        @include media("<mobile") {
            margin-top: 0;
        }
    }

    img,
    .icon_radio_on,
    .icon_radio_off,
    .icon_checkbox_on,
    .icon_checkbox_off {
        width: rem(18);
        height: rem(18);
        margin-right: rem(8);
        font-size: rem(18);
        line-height: rem(21);

        @include media("<mobile") {
            width: mobile(36);
            height: mobile(36);
            margin-right: mobile(12);
            font-size: mobile(36);
            line-height: mobile(36);
        }

        &.icon_checkbox_fill {
            color: $main-color;
        }

        & + *,
        & + * * {
            line-height: rem(21);
            white-space: pre-wrap;

            @include media("<mobile") {
                line-height: mobile(36);
            }
        }
    }

    &.size-l {
        @include media(">=mobile") {
            img {
                margin-top: rem(3);
            }

            img,
            .icon_radio_on,
            .icon_radio_off,
            .icon_checkbox_on,
            .icon_checkbox_off {
                width: rem(20);
                height: rem(20);
                margin-right: rem(10);
                font-size: rem(20);
                line-height: rem(27);

                & + *,
                & + * * {
                    line-height: rem(27);
                }
            }
        }
    }

    &.m-size-l {
        @include media("<mobile") {
            img {
                margin-top: mobile(1);
            }

            img,
            .icon_radio_on,
            .icon_radio_off,
            .icon_checkbox_on,
            .icon_checkbox_off {
                width: mobile(40);
                height: mobile(40);
                margin-right: mobile(12);
                font-size: mobile(40);
                line-height: mobile(42);

                & + *,
                & + * * {
                    line-height: mobile(42);
                }
            }
        }
    }
}

@mixin radio-style {
    display: flex;
    align-items: center;
    font-size: rem(14);
    cursor: pointer;

    &.active {
        font-weight: $font-weight-medium;
        color: $gray-5;

        .rs-icon {
            color: $black;
        }
    }

    .rs-icon {
        margin-right: rem(8);
        font-size: rem(18);
        line-height: rem(21);
        color: $gray-3;

        @include media("<mobile") {
            margin-right: mobile(12);
            font-size: mobile(36);
            line-height: mobile(36);
        }

        & + *,
        & + * * {
            line-height: rem(21);
            white-space: pre-wrap;

            @include media("<mobile") {
                line-height: mobile(36);
            }
        }
    }

    &.size-l {
        @include media(">=mobile") {
            font-size: rem(18);

            .rs-icon {
                margin-right: rem(10);
                font-size: rem(20);
                line-height: rem(27);

                & + *,
                & + * * {
                    line-height: rem(27);
                }
            }
        }
    }

    &.m-size-l {
        @include media("<mobile") {
            font-size: mobile(28);

            .rs-icon {
                margin-right: mobile(12);
                font-size: mobile(40);
                line-height: mobile(42);

                & + *,
                & + * * {
                    line-height: mobile(42);
                }
            }
        }
    }
}
