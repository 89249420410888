@keyframes fadeInAnimation {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadeOutAnimation {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes sizeUpAnimation {
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
}

@keyframes sizeUpBounceAnimation {
    0% {
        transform: scale(0);
    }
    90% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes sizeDownBounceAnimation {
    0% {
        transform: scale(1);
    }
    20% {
        transform: scale(1.3);
    }
    40% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}

@keyframes moveUpAnimation {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes moveDownAnimation {
    from {
        transform: translateY(-100%);
    }
    to {
        transform: translateY(0);
    }
}
