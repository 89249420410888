@import "styles/utils";

.biz-intro-header {
    position: relative;
    z-index: 100;
    height: $header-top-height;
    transition: transform 0.3s;

    @include media("<mobile") {
        height: $header-height-mobile;
    }

    &.fixed {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        background-color: $white;
        transform: translateY(-100%);

        &.active {
            border-bottom: rem(1) solid $gray-1;
            transform: translateY(0);
            color: $gray-5;
        }

        .header-wrapper {
            li.active > a {
                border-bottom-color: $main-color;
            }
        }
    }

    &.sub-active {
        & ~ .business-intro-mobile-menu {
            box-shadow: 0 mobile(6) mobile(10) 0 rgba(0, 0, 0, 0.05);
        }
    }

    .header-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: rem(1050);
        height: 100%;
        margin: 0 auto;

        @include media("<mobile") {
            width: 100%;
            padding: 0 $indent-mobile;
        }

        .reviewshare-logo {
            display: flex;
            margin-right: rem(28);
            padding-top: rem(3);

            @include media("<mobile") {
                margin-right: 0;
                padding-top: 0;
            }

            img {
                width: rem(190);
                height: rem(24);

                @include media("<mobile") {
                    width: auto;
                    height: mobile(30);
                }
            }
        }
    }

    .button-items {
        display: flex;
        align-items: center;

        li {
            & + li {
                margin-left: rem(8);
            }
        }

        .rs-button {
            padding-left: rem(12);
            padding-right: rem(12);
        }

        .icon_download {
            margin-top: 0;
            margin-left: rem(4);
            margin-bottom: rem(2);
        }
    }
}
