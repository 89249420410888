@import "styles/utils";

.global-common-page {
    &:not(#global-main-page) {
        margin-top: rem(40);

        @include media("<mobile") {
            margin-top: mobile(60);
        }
    }
}

.global-common-wrapper {
    max-width: $width-xl;
    margin-left: auto;
    margin-right: auto;

    @include media("<wide-web") {
        max-width: $width-l;
        padding-left: $indent-wide-web;
        padding-right: $indent-wide-web;
    }

    @include media("<mobile") {
        width: auto;
        max-width: initial;
        padding-left: $indent-mobile;
        padding-right: $indent-mobile;
    }
}

.global-common-search-title {
    margin-bottom: rem(32);
    font-size: rem(32);
    font-weight: $font-weight-medium;
    text-align: center;

    @include media("<mobile") {
        margin-bottom: mobile(32);
        font-size: mobile(36);
        color: $gray-5;
    }
}

.global-common-list-wrapper {
    position: relative;
    width: 100%;
    margin: 0 auto rem(20);

    @include media("<mobile") {
        width: auto;
        margin: mobile(20) auto mobile(100);
    }
}

.global-common-search-input-area {
    display: flex;
    flex-direction: column;
    align-items: center;

    > img {
        height: rem(26);

        @include media("<mobile") {
            height: mobile(38);
        }
    }

    .rs-input-wrapper,
    .main-search-input-wrapper {
        width: rem(430);
        margin-top: rem(18);

        @include media("<mobile") {
            width: 100%;
            margin-top: mobile(30);
        }
    }

    .rs-input-wrapper,
    .main-search-input {
        border-radius: rem(70);
        box-shadow: 0 0 rem(10) 0 rgba(230, 232, 237, 0.8);

        &:not(:focus) {
            border-color: transparent;
        }

        @include media("<mobile") {
            border-radius: mobile(90);
            box-shadow: 0 0 mobile(10) 0 rgba(230, 232, 237, 0.8);
        }
    }
}

.global-common-search-count {
    margin-top: rem(40);
    margin-bottom: rem(20);
    font-size: rem(20);

    @include media("<mobile") {
        margin-top: mobile(50);
        margin-bottom: mobile(30);
        font-size: mobile(32);
        color: $gray-5;
    }
}

.global-common-no-search-result {
    margin: rem(200) 0;

    @include media("<mobile") {
        margin: mobile(140) 0 mobile(200);
    }

    span {
        color: $gray-5;
    }
}
