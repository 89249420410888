/*----------------------*/
/*-----   Colors   -----*/
/*----------------------*/

$primary-color: #0067ff;
$secondary-color: #003cff;
$tertiary-color: #5a00ff;

$light-blue: #2887f9;
$main-color: $primary-color;

$white: #ffffff;
$black: #000000;

$gray-0: #f7f7fa;
$gray-1: #e6e8ed;
$gray-2: #9da1a8;
$gray-3: #52565d;
$gray-4: #33363b;
$gray-5: #202225;

$active-color: $secondary-color;
$active-color-light: #e5f0ff;
$active-color-gray: $gray-0;

$text-color: $gray-5;

$blue-point-1: #5eecff;

$blue-lightest: #f7faff;
$green-lightest: #fafffb;

$red-lighter: #fcf1f0;
$orange-lighter: #ffe7d6;
$yellow-lighter: #fff5c9;
$green-lighter: #e2f8e3;
$blue-lighter: #eef5ff;
$purple-lighter: #f5ebff;

$yellow-light: #ffe368;
$orange-light: #ff9d5a;
$red-light: #eb8276;
$green-light: #8bdb8e;
$blue-light: #5e9fff;
$purple-light: #c189f9;

$yellow: #ffd400;
$orange: #ff7326;
$red: #fa4238;
$green: #02c639;
$blue: #0067ff;
$purple: #9727f1;

$yellow-dark: #ffbf00;
$orange-dark: #ff5300;
$red-dark: #d41100;
$green-dark: #01a31d;
$blue-dark: #003ffc;
$purple-dark: #7b00e0;

$red-darker: #d41100;
$orange-darker: #ff4e00;
$blue-darker: #333b58;

$blue-darkest: #001a40;

$chart-background: rgba(174, 208, 250, 0.1);

$naver-blog: #02c639;
$naver-post: #01beff;
$facebook: #0d33a2;
$instagram: #c32aa3;
$kakao-story: #fbc800;
$youtube: #fa4238;
$tiktok: #00d8d1;
$reels: #c32aa3;
$shorts: #fa4238;
$naver-influencer: #0e5e47;
$other-sns: #003ffc;
