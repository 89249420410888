@import "styles/utils";

.messenger-main-message-box-info {
    display: flex;
    padding: $messenger-indent;
    border-bottom: rem(1) solid $gray-1;
    @include messenger-mobile-header;

    @include media("<mobile") {
        height: mobile(135);
    }

    .title-area {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex: 1;
        width: 100%;

        @include media("<mobile") {
            overflow: hidden;
            padding-left: mobile(36);
        }
    }

    h4 {
        @include ellipsis;
        max-width: 100%;
        margin-top: rem(2);
        font-size: rem(12);
        font-weight: $font-weight-normal;

        @include media(">=wide-web") {
            max-width: rem(860);
        }

        @include media("<mobile") {
            max-width: 100%;
            margin-top: mobile(2);
            font-size: mobile(26);
        }
    }

    .back-btn {
        @include only-mobile;
        @include btn-clear-style;

        @include media("<mobile") {
            font-size: mobile(36);
        }
    }

    .to-list {
        position: relative;
    }

    .to-list-wrapper {
        position: absolute;
        top: 0;
        overflow-y: auto;
        width: rem(164);
        max-height: rem(288);
        padding: rem(12);
        border-radius: rem(4);
        background-color: $white;
        box-shadow: 0 rem(3) rem(6) 0 rgba(0, 0, 0, 0.16);
        z-index: 1;

        @include media("<mobile") {
            top: mobile(8);
            left: 50%;
            width: mobile(460);
            max-height: rem(520);
            padding: mobile(24);
            border-radius: mobile(6);
            transform: translateX(-50%);
        }

        li {
            display: flex;
            align-items: center;

            & + li {
                margin-top: rem(8);

                @include media("<mobile") {
                    margin-top: mobile(12);
                }
            }
        }

        p {
            @include ellipsis;
            margin-left: rem(6);
            font-size: rem(12);

            @include media("<mobile") {
                margin-left: mobile(10);
                font-size: mobile(24);
            }
        }
    }

    .rs-more-menu {
        @include media(">=mobile") {
            z-index: 1;
        }

        @include media("<mobile") {
            font-size: mobile(32);
        }
    }

    .common-skeleton {
        width: 20%;

        @include media("<mobile") {
            width: 50%;
        }

        .common-skeleton-item:last-child {
            width: 80%;
        }
    }
}
