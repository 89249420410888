@import "styles/utils";

@keyframes messengerRightAnimation {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}

#messenger-main-side-content {
    flex-shrink: 0;

    @include media("<mobile") {
        display: flex;
        width: 50%;
    }

    .messenger-main-side-content-wrapper {
        display: flex;
        flex-direction: column;
        width: rem(300);
        height: 100%;
        border-left: rem(1) solid $gray-1;
        background-color: $white;

        @include media("<wide-web", ">=mobile") {
            width: rem(270);
        }

        @include media("<mobile") {
            width: 100%;
            padding: 0;
            border-left: none;
            transition: transform 0.3s;
            animation: messengerRightAnimation 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
        }

        &.active {
            @include media("<mobile") {
                transform: translateX(-100%);
            }
        }
    }

    .messenger-main-side-title-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        padding: $messenger-indent;

        @include messenger-mobile-header;

        h3 {
            font-size: rem(16);
            font-weight: $font-weight-medium;

            @include messenger-mobile-header-title;
        }

        .close-btn {
            @include btn-clear-style;
            position: absolute;
            top: 0;
            bottom: 0;
            right: $messenger-indent;

            @include media("<mobile") {
                top: 50%;
                right: $indent-mobile;
                font-size: mobile(32);
                color: $gray-2;
                transform: translateY(-50%);
            }
        }
    }

    .messenger-main-side-content-box {
        flex: 1;
        overflow: hidden;
    }
}
