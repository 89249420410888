@import "styles/utils";

.business-page-template {
    height: 100%;
    background-color: $gray-0;

    @include media("<mobile") {
        min-height: 100vh;
    }

    .global-header {
        .header-wrapper {
            border-bottom-color: transparent;
        }
    }

    .page-body {
        display: flex;
        flex-direction: row;
        position: relative;
        z-index: 0;

        @include media("<mobile") {
            flex-direction: column;
        }

        > * {
            @include media("<mobile") {
                flex-shrink: 0;
            }
        }
    }

    .main-contents {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow-x: hidden;
        max-width: 100%;

        @include media("<mobile") {
            width: 100%;
        }
    }

    .main-contents-body {
        padding: $biz-center-top-padding $biz-center-indent rem(60);

        @include media("<mobile") {
            display: flex;
            flex-direction: column;
            padding: $biz-center-indent-mobile;
        }
    }
}
