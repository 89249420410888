@import "styles/utils";

#messenger-common-launcher-btn {
    display: flex;
    justify-content: right;
    position: fixed;
    height: 0;
    right: rem(32);
    bottom: rem(50);
    z-index: 100;

    @include media("<mobile") {
        right: $indent-mobile;
        bottom: calc(#{$bottom-tab-bar-mobile} + constants(safe-area-inset-bottom) + #{mobile(50)});
        bottom: calc(#{$bottom-tab-bar-mobile} + env(safe-area-inset-bottom) + #{mobile(50)});
    }

    .rs-button {
        position: relative;
        padding-left: rem(20);
        padding-right: rem(20);
        border-radius: rem(90);
        transform: translateY(-100%);

        @include media("<mobile") {
            width: mobile(104);
            margin-top: 0;
            padding-left: 0;
            padding-right: 0;
            border-radius: 50%;
            transform: translateY(-100%);
            box-shadow: 0 mobile(4) mobile(6) 0 rgba(0, 0, 0, 0.2);
        }

        .rs-icon {
            margin-right: rem(6);
            font-size: rem(20);

            @include media("<mobile") {
                margin-right: 0;
                font-size: mobile(44);
            }
        }

        .text {
            @include only-web;
        }

        .new-mark {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            width: rem(16);
            height: rem(16);
            border: rem(3) solid $white;
            border-radius: 50%;
            background-color: $orange-dark;

            @include media("<mobile") {
                width: mobile(28);
                height: mobile(28);
                border-width: mobile(5);
            }
        }
    }
}
