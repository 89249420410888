@import "styles/utils";

.field {
    & + .field {
        margin-top: rem(20);

        @include media("<mobile") {
            margin-top: mobile(40);
        }
    }
}

.label {
    display: block;
    margin-bottom: rem(6);
    font-size: rem(14);
    color: $gray-5;

    @include media("<mobile") {
        margin-bottom: mobile(16);
        font-size: mobile(26);
    }
}
