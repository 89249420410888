@import "styles/utils";

.messenger-main-brand-history-item {
    padding: rem(22) 0;
    border-bottom: rem(1) solid $gray-1;

    @include media("<mobile") {
        padding: mobile(50) 0;
        border-bottom-width: mobile(1);
    }

    .category-info {
        display: flex;
        align-items: center;
        overflow: hidden;
    }

    .category-sub-info {
        margin-top: rem(14);
        color: $gray-3;

        @include media("<mobile") {
            margin-top: mobile(26);
        }
    }

    .category-sub-info-item {
        display: flex;
        justify-content: space-between;
        font-size: rem(12);

        @include media("<mobile") {
            font-size: mobile(24);
        }

        & + .category-sub-info-item {
            margin-top: rem(4);

            @include media("<mobile") {
                margin-top: mobile(14);
            }
        }

        * {
            flex-shrink: 0;
        }
    }

    .category-text {
        overflow: hidden;

        p {
            @include ellipsis;
            margin-bottom: rem(-1);
            font-size: rem(14);
            font-weight: $font-weight-medium;

            @include media("<mobile") {
                margin-bottom: mobile(-1);
                font-size: mobile(28);
                color: $gray-5;
            }
        }

        span {
            font-size: rem(13);
            white-space: pre-wrap;
            color: $gray-3;

            @include media("<mobile") {
                font-size: mobile(26);
            }
        }
    }

    img {
        display: block;
        flex-shrink: 0;
        width: rem(40);
        height: rem(40);
        margin-right: rem(8);
        border-radius: 50%;

        @include media("<mobile") {
            width: mobile(70);
            height: mobile(70);
            margin-right: mobile(24);
        }
    }
}
