@import "styles/utils";

.business-center-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: $header-top-height;
    padding: 0 rem(30);
    background-color: $white;
    border-bottom: 1px solid $gray-1;

    .logo-area {
        display: flex;
        align-items: center;

        .reviewshare-logo {
            display: flex;
            width: rem(190);
            height: rem(24);

            img {
                display: block;
                width: 100%;
            }
        }

        .reviewshare-business-text {
            margin-left: rem(10);
            font-size: rem(20);
            font-weight: $font-weight-bold;
            color: $gray-3;

            @include media("<wide-web", ">=mobile") {
                margin-left: rem(8);
            }
        }
    }

    .header-menu {
        display: flex;
        align-items: center;
        font-weight: $font-weight-medium;
        color: $gray-3;
    }

    .business-name {
        font-size: rem(14);
        line-height: 1.43;
    }

    .move-manage-my-info-btn {
        margin-left: rem(10);
        padding: rem(2) rem(10);
        border-radius: rem(12);
        border: 1px solid $gray-1;
        line-height: 1.5;
        font-size: rem(12);
    }

    .logout-btn {
        margin-left: rem(4);
        padding: rem(2) rem(10);
        border-radius: rem(12);
        color: $gray-3;
        font-weight: $font-weight-medium;
        font-size: rem(12);
        line-height: 1.5;
        background-color: $gray-0;
    }

    .alarm-link {
        display: flex;
        justify-content: center;
        align-items: center;
        width: rem(32);
        height: rem(32);
        margin-left: rem(18);
        font-size: rem(16);
        border: rem(1) solid $gray-1;
        border-radius: 50%;

        &.hasUnreadAlarm {
            .rs-icon {
                @include unread-mark(absolute);
            }
        }

        .rs-icon {
            padding: rem(6) rem(1);
        }
    }

    .move-service-center-btn {
        display: inline-block;
        position: relative;
        line-height: 1.43;
        margin-left: rem(18);
        padding-left: rem(18);
        font-size: rem(14);

        &::before {
            content: "";
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
            width: 1px;
            height: rem(20);
            background-color: $gray-1;
        }
    }
}
