@import "styles/utils";

#bottom-tab-bar-tab-menu-items {
    display: flex;
    align-items: center;
    width: 100%;
    height: $bottom-tab-bar-mobile - mobile(40);

    .tab-menu-item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        flex: 1;
        height: 100%;
        color: $gray-3;

        &.active {
            color: $main-color;
        }

        img {
            display: flex;
            align-items: center;
            flex: 1;
            width: mobile(34);
            height: mobile(34);
            margin-bottom: mobile(4);
        }

        .tab-menu-item {
            font-size: mobile(20);
        }
    }

    .tab-menu-label {
        font-size: mobile(22);
        font-weight: $font-weight-medium;
        text-align: center;
        line-height: 1.3;
    }
}
