@import "styles/utils";

.checkModal {
    padding: rem(30) rem(30) rem(50);

    @include media("<mobile") {
        padding: mobile(50) $indent-mobile mobile(60);
    }
}

.title {
    font-size: rem(20);
    font-weight: $font-weight-normal;
    text-align: center;
    line-height: 1.56;
    color: $gray-3;

    @include media("<mobile") {
        font-size: mobile(36);
    }

    .nickname {
        width: 100%;
        font-weight: $font-weight-bold;
        color: $gray-5;
    }
}

.functionItems {
    margin-top: rem(30);

    @include media("<mobile") {
        margin-top: mobile(40);
    }
}

.functionItem {
    & + .functionItem {
        margin-top: rem(24);

        @include media("<mobile") {
            margin-top: mobile(40);
        }
    }
}

.functionTitle {
    margin-bottom: rem(8);
    font-size: rem(14);
    font-weight: $font-weight-medium;
    text-align: left;
    white-space: nowrap;
    color: $gray-5;

    @include media("<mobile") {
        margin-bottom: mobile(20);
        font-size: mobile(28);
        text-align: center;
        line-height: 1.5;
        white-space: pre-wrap;
    }
}

.functionDesc {
    padding: rem(16) rem(20);
    border-radius: rem(10);
    font-size: rem(13);
    white-space: pre-wrap;
    line-height: 1.77;
    text-align: left;
    background-color: $gray-0;
    color: $gray-4;

    @include media("<mobile") {
        padding: mobile(32) mobile(36);
        border-radius: mobile(20);
        font-size: mobile(24);
        line-height: 1.7;
        color: $gray-3;
    }
}
