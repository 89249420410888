@import "styles/utils";

.private-page-template {
    .page-body {
        display: flex;
        flex-direction: column;
        flex: 1;
        position: relative;
        z-index: 0;

        @include media("<mobile") {
            padding: 0 $indent-mobile;
        }
    }

    .global-footer {
        @include media("<mobile") {
            width: auto;
            margin: 0 (-$indent-mobile);
        }
    }
}
