@import "styles/utils";

.messenger-side-empty-content {
    margin-top: rem(150);
    font-size: rem(14);
    text-align: center;
    color: $gray-2;

    @include media("<mobile") {
        margin-top: mobile(300);
        font-size: mobile(28);
    }

    .rs-icon {
        font-size: rem(20);

        @include media("<mobile") {
            font-size: mobile(50);
        }
    }

    p {
        margin-top: rem(10);

        @include media("<mobile") {
            margin-top: mobile(20);
        }
    }
}
