@import "styles/utils";

.common-skeleton {
    > span {
        display: flex;
        flex-direction: column;
    }

    .react-loading-skeleton + .react-loading-skeleton {
        margin-top: rem(4);

        @include media("<mobile") {
            margin-top: mobile(4);
        }
    }
}
