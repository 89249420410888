.icon_deposit {
    display: inline-flex;
    align-items: center;
    color: $main-color;
    font-size: rem(13);
    margin-right: rem(2.9);

    @include media("<mobile") {
        font-size: mobile(24);
        margin-right: mobile(3.3);
    }
}
