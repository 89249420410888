@import "styles/utils";

$messenger-file-list-move-btn-width: rem(38);
$messenger-file-list-item-margin: rem(6);

.messenger-main-write-message {
    padding: rem(14) $messenger-indent rem(18);
    border-top: rem(1) solid $gray-1;
    background-color: $white;

    @include media("<mobile") {
        padding: mobile(18) $indent-mobile;
        padding-bottom: calc(#{mobile(18)} + constants(safe-area-inset-bottom));
        padding-bottom: calc(#{mobile(18)} + env(safe-area-inset-bottom));
        border-top-width: mobile(1);
    }

    .input-area {
        display: flex;

        @include media("<mobile") {
            align-items: flex-end;
        }

        button {
            height: auto;
            margin-left: rem(10);
            padding-left: rem(20);
            padding-right: rem(20);

            @include media("<mobile") {
                @include btn-clear-style;
                height: mobile(86);
                margin-left: $indent-mobile;
                font-size: mobile(44);
                color: $main-color;
            }

            &:disabled {
                @include media("<mobile") {
                    color: $gray-2;
                }
            }
        }

        textarea {
            flex: 1;
            overflow: hidden;
            height: rem(38);

            @include media("<mobile") {
                height: mobile(86);
            }

            &.two-lines {
                height: rem(60);

                @include media("<mobile") {
                    height: mobile(128);
                }
            }

            &.three-lines {
                overflow: auto;
                height: rem(86);

                @include media("<mobile") {
                    height: mobile(171);
                }
            }
        }
    }

    @include media("<mobile") {
    }
}
