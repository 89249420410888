@import "styles/utils";

.upload-multi-file-box {
    .rs-button {
        width: 100%;
        margin-bottom: rem(10);

        @include media("<mobile") {
            margin-bottom: 0;
        }

        .rs-icon {
            margin-right: rem(6);
            font-size: rem(20);
            color: $gray-3;

            @include media("<mobile") {
                margin-right: mobile(10);
                font-size: mobile(32);
            }
        }
    }

    .file-item-list {
        border: rem(1) solid $gray-1;

        @include media("<mobile") {
            border: none;
        }
    }

    .file-item-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: rem(6) rem(19);
        border-bottom: rem(1) solid $gray-1;
        font-size: rem(13);
        background-color: $gray-0;
        color: $gray-2;
    }

    .file-item-box-wrapper {
        overflow-y: scroll;
        height: rem(133);
        padding: rem(5) 0;

        @include media("<mobile") {
            display: flex;
            overflow-x: auto;
            overflow-y: hidden;
            height: auto;
            padding: 0;
        }

        @include media(">=mobile") {
            &::-webkit-scrollbar {
                width: rem(16);
            }

            &::-webkit-scrollbar-thumb {
                background: $gray-2;
                border: rem(6) solid #fff;
            }
        }

        &.empty-content {
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            padding: 0;
            font-size: rem(12);
            color: $gray-2;
        }

        &:not(.empty-content) {
            @include media("<mobile") {
                margin-top: mobile(20);
            }
        }
    }

    .file-download-link {
        display: flex;

        &.has-link {
            &:hover {
                text-decoration: underline;
            }
        }
    }

    .file-item-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        padding: rem(5) rem(3) rem(5) rem(19);
        font-size: rem(13);

        @include media("<mobile") {
            padding: mobile(20);
            border-radius: mobile(60);
            font-size: mobile(20);
            background-color: $gray-0;
            color: $gray-5;
        }

        & + .file-item-box {
            @include media("<mobile") {
                margin-left: mobile(12);
            }
        }

        .file-item-remove-btn {
            color: $gray-2;
            cursor: pointer;

            @include media("<mobile") {
                font-size: mobile(28);
            }

            &:hover {
                @include media(">=mobile") {
                    color: $gray-5;
                }
            }
        }
    }

    .file-item-name {
        max-width: rem(210);

        @include media("<mobile") {
            max-width: mobile(170);
        }
    }

    .file-item-type {
        flex: 1;
    }

    .file-item-remove-btn {
        width: rem(32);
        text-align: center;

        @include media("<mobile") {
            width: auto;
            margin-left: mobile(10);
        }
    }
}
