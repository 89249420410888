@import "styles/utils";

#login-modal {
    overflow: hidden;
    width: rem(400);

    @include media(">=mobile") {
        position: relative;
    }

    @include media("<mobile") {
        overflow: initial;
        width: 100%;
        height: 100%;
        padding: mobile(70) mobile(50) mobile(90);

        .login-modal-inner,
        .login-modal-step-wrapper,
        .login-modal-step {
            height: 100%;
        }
    }

    .rs-standard-modal-scroll-area {
        overflow: hidden;
    }

    .login-modal {
        &-inner {
            overflow: hidden;

            @include media(">=mobile") {
                height: rem(490);
            }
        }

        &-step-wrapper {
            display: flex;
            position: absolute;
            // top: 50%;
            top: rem(30);
            left: 0;
            width: 200%;
            padding-top: rem(20);
            // transform: translateY(-50%);
            transition: transform 0.3s;

            @include media("<mobile") {
                top: 0;
                padding-top: 0;
            }

            &.last {
                transform: translateX(-50%);
            }
        }

        &-step {
            display: flex;
            flex-direction: column;
            width: 50%;

            @include media("<mobile") {
                padding: mobile(100) mobile(82) mobile(120);
            }

            & + .login-modal-step {
                padding-left: rem(30);
                padding-right: rem(30);

                @include media("<mobile") {
                    padding: mobile(100) mobile(60) mobile(120);
                }
            }
        }
    }

    .logo-wrapper {
        img {
            display: block;
            width: rem(44);
            height: rem(25);
            margin: 0 auto;

            @include media("<mobile") {
                width: mobile(72);
                height: mobile(40);
            }
        }
    }

    .select-user-type {
        margin-top: rem(18);
        margin-bottom: rem(30);

        @include media("<mobile") {
            margin-top: mobile(30);
            margin-bottom: mobile(60);
        }

        ul {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: rem(12);

            @include media("<mobile") {
                margin-bottom: mobile(18);
            }

            li {
                @include check-input;

                @include media(">=mobile") {
                    &:first-child {
                        img {
                            width: rem(50);
                        }
                    }

                    &:last-child {
                        img {
                            width: rem(70);
                        }
                    }
                }
            }

            li + li {
                margin-left: rem(16);

                @include media("<mobile") {
                    margin-left: mobile(32);
                }
            }

            label {
                @include radio-style;
                display: flex;
                align-items: center;
                font-size: rem(20);
                font-weight: $font-weight-bold;

                @include media("<mobile") {
                    font-size: mobile(44);
                    color: $gray-5;
                }

                .rs-icon {
                    margin-right: rem(6);
                }
            }
        }

        .user-type-tip {
            font-size: rem(14);
            font-weight: $font-weight-normal;
            text-align: center;
            white-space: nowrap;
            color: $gray-3;

            @include media("<mobile") {
                text-align: center;
                width: auto;
                margin: 0 mobile(-10);
                font-size: mobile(26);
            }

            span {
                font-weight: $font-weight-medium;
            }
        }
    }

    .login-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        > div {
            position: relative;
            width: 100%;

            + div {
                margin-top: rem(8);

                @include media("<mobile") {
                    margin-top: mobile(10);
                }
            }
        }

        input {
            height: rem(50);
            width: 100%;
            border: rem(1) solid $gray-1;
            border-radius: rem(4);
            font-size: rem(14);
            padding: 0 rem(15);

            @include media("<mobile") {
                height: mobile(86);
                border-width: mobile(1);
                border-radius: mobile(6);
                font-size: mobile(26);
                padding: 0 mobile(80) 0 mobile(24);
            }

            &::placeholder {
                color: $gray-2;
            }
        }

        img {
            position: absolute;
            top: 50%;
            right: mobile(24);
            width: mobile(34);
            height: mobile(34);
            transform: translateY(-50%);
        }

        .login-btn {
            margin-top: rem(10);

            @include media("<mobile") {
                margin-top: mobile(14);
            }
        }

        > button {
            width: 100%;
        }

        .login-options {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            margin-top: rem(18);
            // margin-bottom: rem(30);

            @include media("<mobile") {
                margin-top: mobilr(30);
                margin-bottom: mobile(30);
            }

            a {
                font-size: rem(14);
                font-weight: $font-weight-normal;
                line-height: 1.43;

                @include media("<mobile") {
                    font-size: mobile(26);
                }
            }

            a + a {
                &:before {
                    content: "";
                    display: inline-block;
                    width: rem(1);
                    height: rem(12);
                    margin: 0 rem(6);
                    background-color: $gray-1;

                    @include media("<mobile") {
                        width: mobile(1);
                        height: mobile(20);
                        margin: 0 mobile(10);
                    }
                }
            }
        }

        .find-btns {
            position: relative;
            top: rem(-1);
        }

        .save-id {
            @include check-input;

            label {
                display: flex;
                @include checkbox-style;

                p {
                    font-size: rem(14);

                    @include media("<mobile") {
                        font-size: mobile(26);
                    }
                }
            }
        }
    }

    .sns-login {
        width: rem(340);
        margin: 0 auto;

        @include media("<mobile") {
            width: 100%;
        }

        .login-btn {
            width: 100%;

            .rs-icon {
                font-size: rem(18);
                margin-right: rem(8);

                &.icon_mail_fill {
                    font-size: rem(16);
                }

                @include media("<mobile") {
                    font-size: mobile(30);

                    &.icon_mail_fill {
                        font-size: mobile(28);
                    }
                }
            }
        }
    }

    .text-with-horizon-line {
        display: flex;
        justify-content: center;
        position: relative;
        width: 100%;
        margin-top: rem(30);
        font-size: rem(14);
        line-height: 1.43;
        color: $gray-3;

        @include media("<mobile") {
            margin-top: mobile(50);
            font-size: mobile(24);
            line-height: 1.5;
        }

        .horizon-line {
            z-index: 0;
            width: 100%;
            height: rem(1);
            background-color: $gray-1;
            position: absolute;
            top: 49%;
            left: 0%;
        }

        p {
            position: relative;
            z-index: 10;
            display: inline-block;
            padding: 0 rem(10);
            background-color: $white;
        }
    }

    .sns-login-list {
        margin-top: rem(24);
        display: flex;
        justify-content: center;

        @include media("<mobile") {
            margin-top: mobile(40);
        }

        a {
            width: rem(50);
            height: rem(50);
            border-radius: 50%;
            color: $white;
            text-align: center;
            line-height: rem(50);

            @include media("<mobile") {
                width: mobile(86);
                height: mobile(86);
                line-height: mobile(86);
            }

            &.apple-login-btn {
                font-size: rem(22);
                background-color: $black;

                @include media("<mobile") {
                    font-size: mobile(40);
                }
            }

            &.naver-login-btn {
                font-size: rem(16);
                background-color: #1ec800;

                @include media("<mobile") {
                    font-size: mobile(30);
                }
            }
        }

        a + a {
            margin-left: rem(12);

            @include media("<mobile") {
                margin-left: mobile(20);
            }
        }
    }

    .prev-step-title {
        display: flex;
        align-items: center;
        margin-bottom: rem(30);
        cursor: pointer;

        @include media("<mobile") {
            margin-bottom: mobile(64);
            color: $gray-5;
        }

        p {
            font-size: rem(20);
            font-weight: $font-weight-medium;

            @include media("<mobile") {
                font-size: mobile(36);
            }
        }

        .rs-icon {
            margin-right: rem(14);
            font-size: rem(16);

            @include media("<mobile") {
                margin-right: mobile(30);
                font-size: mobile(26);
            }
        }
    }

    .review-share-visitor {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        flex: 1;
        font-size: rem(14);
        line-height: 1.43;
        font-weight: $font-weight-medium;
        color: $gray-5;

        @include media("<mobile") {
            font-size: mobile(26);
            line-height: 1.46;
        }

        a {
            display: flex;
            align-items: center;
        }

        a:last-child {
            &::before {
                content: "";
                position: relative;
                top: rem(2);
                display: inline-block;
                background-color: $gray-1;
                width: rem(1);
                height: rem(16);
                margin: 0 rem(8);

                @include media("<mobile") {
                    top: mobile(2);
                    width: mobile(1);
                    height: mobile(24);
                    margin: 0 mobile(14);
                }
            }
        }
    }

    .signin-btn-area {
        display: flex;
        justify-content: center;
        margin-top: rem(152);

        @include media("<mobile") {
            margin-top: auto;
        }

        a {
            min-width: rem(126);
            padding: 0 rem(10);
            border: rem(1) solid $gray-1;
            border-radius: rem(60);
            font-size: rem(12);
            font-weight: $font-weight-medium;
            text-align: center;
            line-height: rem(30);
            white-space: nowrap;
            cursor: pointer;
            display: inline-block;

            @include media("<mobile") {
                min-width: mobile(264);
                padding: 0 mobile(30);
                border-width: mobile(1);
                border-radius: mobile(60);
                font-size: mobile(24);
                line-height: mobile(58);
            }
        }
    }
}
