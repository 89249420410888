@import "styles/utils";

.pointArea {
    margin-bottom: rem(20);

    @include media("<mobile") {
        margin-bottom: mobile(32);
    }

    .pointsTitle {
        margin-bottom: rem(4);
        font-weight: $font-weight-medium;

        @include media("<mobile") {
            margin-top: mobile(20);
            margin-bottom: mobile(2);
        }
    }

    .totalAmount {
        font-size: rem(30);
        font-weight: $font-weight-bold;
        line-height: 1.5;
        color: $main-color;

        @include media("<mobile") {
            font-size: mobile(50);
        }
    }

    .myPoints {
        display: flex;
        align-items: center;
        font-size: rem(14);
        color: $gray-5;

        @include media("<mobile") {
            font-size: mobile(24);
        }

        &:before {
            content: "";
            display: block;
            width: rem(4);
            height: rem(20);
            margin-right: rem(10);
            background-color: $gray-1;

            @include media("<mobile") {
                width: mobile(4);
                height: mobile(32);
                margin-right: mobile(16);
            }
        }
    }

    .chargeLink {
        margin-left: rem(4);
        padding-left: rem(24);
        padding-right: rem(24);

        @include media("<mobile") {
            margin-left: mobile(10);
            padding-left: mobile(40);
            padding-right: mobile(40);
        }
    }
}

.pointInfoArea {
    padding-top: rem(20);
    padding-bottom: rem(20);
    border-top: rem(1) dotted $gray-1;
    border-bottom: rem(1) dotted $gray-1;

    @include media("<mobile") {
        padding-top: mobile(40);
        padding-bottom: mobile(40);
        border-top-width: mobile(1);
        border-bottom-width: mobile(1);
    }

    li {
        display: flex;
        justify-content: space-between;
        font-size: rem(14);
        color: $gray-3;

        @include media("<mobile") {
            font-size: mobile(28);

            p {
                color: $gray-2;
            }
        }

        & + li {
            margin-top: rem(12);

            @include media("<mobile") {
                margin-top: mobile(20);
            }
        }
    }
}

.cautionList {
    margin-top: rem(12);
    color: $gray-2;

    @include media("<mobile") {
        margin-top: mobile(32);
    }
}
