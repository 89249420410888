@import "styles/utils";

.messenger-side-searched-message-box-list {
    margin-bottom: rem(20);
    padding: 0 rem(20);
    transition: margin-left 0.3s;

    @include media("<mobile") {
        margin-bottom: mobile(40);
        padding: 0 (-$indent-mobile);
    }

    &.all {
        .searched-message-box-list {
            li:nth-child(5):last-child {
                border-bottom: rem(1) solid $gray-1;

                @include media("<mobile") {
                    border-bottom-width: mobile(1);
                }
            }
        }
    }

    &:not(.all) {
        margin-top: rem(20);

        @include media("<mobile") {
            margin-top: mobile(32);
        }
    }

    .searched-message-box-title {
        margin: rem(24) 0 rem(8);
        font-size: rem(12);
        color: $gray-3;

        @include media("<mobile") {
            margin: mobile(40) 0 mobile(24);
            font-size: mobile(24);
        }
    }

    .searched-message-box-list-background {
        border-radius: rem(16);
        background-color: $gray-0;

        @include media("<mobile") {
            border-radius: mobile(40);
        }
    }

    .list-more-btn {
        width: 100%;
        color: $main-color;
    }
}
