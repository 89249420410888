@import "styles/utils";

.messenger-searched-message-box-item {
    padding: rem(20) rem(14);
    cursor: pointer;

    @include media("<mobile") {
        padding: mobile(32) mobile(40);
    }

    &.message {
        .text-area {
            .other-name {
                font-size: rem(12);
                color: $gray-3;

                @include media("<mobile") {
                    font-size: mobile(24);
                }
            }

            .date {
                @include media("<mobile") {
                    font-size: mobile(24);
                }
            }
        }

        .message-area {
            .message {
                font-size: rem(14);
                color: $gray-5;

                @include media("<mobile") {
                    font-size: mobile(28);
                }
            }
        }
    }

    & + .messenger-searched-message-box-item {
        border-top: rem(1) solid $gray-1;

        @include media("<mobile") {
            border-top-width: mobile(1);
        }
    }

    .profile-text-area {
        display: flex;
        align-items: center;
    }

    .rs-profile {
        margin-right: rem(14);

        @include media("<mobile") {
            margin-right: mobile(32);
        }
    }

    .text-area {
        flex: 1;
        overflow: hidden;

        .info-area {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .other-name {
            @include ellipsis;
            font-size: rem(14);

            @include media("<mobile") {
                font-size: mobile(30);
                color: $gray-5;
            }
        }

        .date {
            font-size: rem(12);
            color: $gray-2;

            @include media("<mobile") {
                font-size: mobile(26);
            }
        }
    }

    .message-area {
        display: flex;
        justify-content: space-between;
        margin-top: rem(3);

        @include media("<mobile") {
            margin-top: mobile(6);
        }

        .message {
            @include ellipsis;
            font-size: rem(13);
            color: $gray-3;

            @include media("<mobile") {
                font-size: mobile(26);
            }
        }

        .new-mark {
            display: flex;
            justify-content: center;
            align-items: center;
            width: rem(15);
            height: rem(15);
            margin-top: rem(2);
            padding-bottom: rem(1);
            border-radius: 50%;
            font-size: rem(8);
            background-color: $orange-dark;
            color: $white;

            @include media("<mobile") {
                width: mobile(36);
                height: mobile(36);
                margin-top: mobile(1);
                padding-bottom: mobile(1);
                font-size: mobile(22);
            }
        }
    }

    .brand-area {
        display: flex;
        align-items: center;
        width: 100%;
        margin-top: rem(8);
        padding: rem(5) rem(12);
        border: rem(1) solid $gray-1;
        border-radius: rem(6);
        font-size: rem(12);
        background-color: $white;

        @include media("<mobile") {
            margin-top: mobile(32);
            padding: mobile(18) $indent-mobile;
            border-width: mobile(1);
            border-radius: mobile(20);
            font-size: mobile(26);
            color: $gray-5;
        }

        > span {
            display: flex;
            align-items: center;
            flex-shrink: 0;
            color: $gray-2;

            &:after {
                content: "";
                display: block;
                width: rem(1);
                height: rem(11);
                margin: rem(1) rem(6) 0;
                background-color: $gray-1;

                @include media("<mobile") {
                    width: mobile(2);
                    height: mobile(24);
                    margin: 0 mobile(20);
                }
            }
        }

        .rs-icon {
            margin-right: rem(4);
        }

        p {
            @include ellipsis;
        }
    }

    .brand-text {
        @include ellipsis;
        font-size: rem(12);
        color: $gray-3;

        @include media("<mobile") {
            font-size: mobile(24);
        }

        .rs-icon {
            margin-right: rem(4);

            @include media("<mobile") {
                margin-right: mobile(8);
            }
        }
    }
}
