body {
    margin: 0;
    box-sizing: border-box;
    color: $text-color;

    &.lock-position {
        overflow: hidden !important;
        touch-action: none;
        -ms-touch-action: none;
    }

    // @include media("<mobile") {
    //     color: $gray-3;
    // }

    > iframe {
        display: none;
    }
}

#modal {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9000;
}

a {
    text-decoration: none;
    color: inherit;
    -webkit-tap-highlight-color: transparent;
}

* {
    box-sizing: border-box;
    font-family: "Noto Sans Korean", sans-serif;

    @include media(">=mobile") {
        &::-webkit-scrollbar {
            width: rem(6);
            height: rem(6);
        }

        &::-webkit-scrollbar-thumb {
            background-color: $gray-2;
            border-radius: rem(10);
        }

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }
    }

    @include media("<mobile") {
        &::-webkit-scrollbar {
            display: none;
            width: 0;
            height: 0;
        }
    }
}

*:focus {
    outline: none;
}

html,
body {
    height: 100%;
    font-size: $font-size-m;
    text-size-adjust: none;
    -webkit-text-size-adjust: none;
    -ms-text-size-adjust: none;
    -moz-text-size-adjust: none;
    -o-text-size-adjust: none;

    line-height: 1.4;
    letter-spacing: rem(-0.3);

    // @include media("<web") {
    //     font-size: $font-size-s;
    // }

    // @include media("<wide-web") {
    //     font-size: $font-size-xs;
    // }

    @include media(">=mobile") {
        min-width: $width-l;
    }

    @include media("<mobile") {
        font-size: mobile(24);
        letter-spacing: mobile(-0.3);
    }
}

#root {
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 100%;

    @include media("<mobile") {
        min-height: calc(100% - #{$header-height-mobile});
    }
}

ul,
ol {
    list-style: none;
    padding: 0;
    margin: 0;
}

dd {
    margin-left: 0;
}

h1,
h2,
h3,
h4,
h5 {
    margin: 0;
    font-size: inherit;
    line-height: 1.4;
}

p {
    margin: 0;
}

button {
    cursor: pointer;
}

fieldset {
    margin: 0;
    padding: 0;
    border: none;
    min-inline-size: initial;
}

input,
input[type="text"],
input[type="number"],
input[type="email"],
input[type="tel"],
input[type="search"],
input[type="password"],
textarea {
    -webkit-appearance: none;

    &::placeholder {
        line-height: normal;
    }
    &::-webkit-input-placeholder {
        line-height: normal;
    }
    &::-moz-placeholder {
        line-height: normal;
    }

    &::-ms-input-placeholder {
        color: $gray-2;
    }
    &::-webkit-input-placeholder {
        color: $gray-2;
    }
    &::-moz-input-placeholder {
        color: $gray-2;
    }

    &:disabled {
        color: $gray-2;
        opacity: 1;
        -webkit-text-fill-color: inherit;
    }

    &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px white inset;
    }

    &:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0 50px white inset;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
}

input[type="file"] {
    display: none !important;
}

textarea {
    resize: none;
    box-sizing: border-box;
    outline: 0;
    box-shadow: none;
    appearance: none;
    -webkit-appearance: none;
    @include textarea-style;
}

.react-date-picker {
    .react-date-picker__calendar {
        width: rem(400);
        max-width: 100%;
        margin-top: rem(12);
        margin-bottom: rem(12);

        @include media("<mobile") {
            width: 100%;
            margin-top: mobile(20);
            margin-bottom: mobile(20);
        }
    }

    .react-calendar {
        width: 100%;

        @include media(">=mobile") {
            min-width: rem(300);
        }

        @include media("<mobile") {
            border-radius: mobile(6);
        }
    }

    > .react-date-picker__wrapper,
    .react-calendar__navigation__next2-button,
    .react-calendar__navigation__prev2-button {
        display: none;
    }

    .react-calendar__navigation {
        @include media("<mobile") {
            height: mobile(70);
            margin-bottom: 0;
        }
    }

    .react-calendar__century-view__decades,
    .react-calendar__decade-view__years,
    .react-calendar__year-view__months,
    .react-calendar__month-view__days {
        @include media("<mobile") {
            height: mobile(320);
        }

        button {
            @include media("<mobile") {
                padding: 0;
            }
        }
    }

    button {
        @include media("<mobile") {
            font-size: mobile(24);
        }
    }
}

.root-wrapper {
    flex: 1;

    &.preventPage {
        @include media(">=mobile") {
            display: none;
        }
    }
}

#launcher {
    @include media("<mobile") {
        bottom: mobile(150) !important;
    }
}

.react-loading-skeleton {
    background-color: #eff0f5 !important;
    background-image: linear-gradient(90deg, #eff0f5, #e9e9f0, #eff0f5) !important;
}

.react-multi-carousel-track {
    touch-action: pan-y;
    transition: transform 0.3s !important;
}

button {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-use-select: none;
    user-select: none;
}
