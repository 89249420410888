@import "styles/utils";

.biz-center-side-menu {
    width: rem(210);
    min-width: rem(210);
    min-height: calc(100vh - 79px);
    border-right: rem(1) solid $gray-1;
    background-color: #fcfcfd;

    @include media(">=mobile", "<web") {
        width: rem(190);
        min-width: rem(190);
    }

    @include media("<mobile") {
        position: sticky;
        top: $header-height-mobile;
        z-index: $header-z-index;
        width: auto;
        min-width: auto;
        min-height: auto;
        background-color: $white;
    }

    &.isSticky {
        @include media("<mobile") {
            box-shadow: 0 mobile(3) mobile(6) 0 rgba($black, 0.06);
        }
    }

    &:not(.isSticky) {
        & ~ .biz-center-side-sub-menu {
            box-shadow: 0 mobile(3) mobile(6) 0 rgba($black, 0.06);
        }
    }

    .top-button-area {
        @include only-web;
        padding: rem(24) rem(30);

        @include media(">=mobile", "<web") {
            padding: rem(24) rem(24);
        }

        .rs-icon {
            margin-left: rem(2);
            font-size: rem(12);
            font-weight: $font-weight-bold;

            @include envn {
                margin-top: rem(3);
            }
        }
    }

    .main-menu-list {
        @include media("<mobile") {
            display: flex;
            align-items: flex-end;
            overflow-x: auto;
            // position: sticky;
            // position: fixed;
            // top: $header-height-mobile - mobile(1);
            height: $header-bottom-height-mobile;
            padding: rem(2) $indent-mobile mobile(24);
            border-bottom: mobile(1) solid $gray-1;
            background-color: $white;
        }
    }

    .main-menu-item {
        color: $blue-darker;

        @include media("<mobile") {
            flex-shrink: 0;
        }

        &.active {
            @include media(">=mobile") {
                background-color: $gray-0;
            }
        }

        &:not(.active) {
            .main-menu-collapse {
                color: $gray-3;
            }
        }

        & + .main-menu-item {
            @include media("<mobile") {
                margin-left: mobile(10);
            }
        }

        .project {
            li:last-child {
                position: relative;
                padding-top: rem(20);
                margin-top: rem(13);

                &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: rem(30);
                    display: inline-block;
                    background-color: $gray-1;
                    width: rem(150);
                    height: rem(1);

                    @include media(">=mobile", "<web") {
                        left: rem(24);
                    }
                }
            }
        }
    }

    .main-menu-collapse {
        @include only-web;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: rem(13) rem(30);

        @include media(">=mobile", "<web") {
            padding: rem(16) rem(24);
        }

        h3 {
            display: flex;
            align-items: center;
            font-size: rem(16);
            font-weight: $font-weight-medium;
            line-height: 1.5;

            @include media(">=mobile", "<web") {
                font-size: rem(16);
            }
        }

        .icon_arrow_up,
        .icon_arrow_down {
            font-size: rem(14);
            font-weight: $font-weight-bold;

            @include media(">=mobile", "<web") {
                font-size: rem(12);
            }
        }

        .icon_new_mark_circle {
            margin-left: rem(4);
            font-size: rem(18);
            color: $red;
        }
    }

    .main-menu-btn {
        @include only-mobile;
        padding-left: mobile(36);
        padding-right: mobile(36);

        .icon_new_mark_circle {
            display: flex;
            justify-content: center;
            align-items: center;
            width: mobile(28);
            height: mobile(28);
            margin-left: mobile(6);
            border-radius: 50%;
            font-size: mobile(32);
            color: $red;
            background-color: $white;
        }
    }

    .sub-menu-list {
        @include only-web;
        padding: 0 0 rem(13);
    }

    .sub-menu-item {
        @include only-web;
        padding: 0 0 rem(7) rem(30);
        font-size: rem(13);
        font-weight: $font-weight-normal;
        line-height: 1.43;

        @include media(">=mobile", "<web") {
            padding: 0 0 rem(7) rem(24);
            font-size: rem(12);
        }

        &.active {
            font-weight: 600;
        }

        &:hover {
            font-weight: 600;
        }
    }
}

.biz-center-side-sub-menu {
    background-color: $white;

    .rs-tabs {
        @include only-mobile;
        padding: 0 $indent-mobile;
    }
}
