@import "styles/utils";

.messenger-main-side-open-buttons {
    margin-bottom: rem(12);

    @include media("<mobile") {
        margin: 0 (-$indent-mobile) mobile(18);
    }

    .button-area-wrapper {
        display: flex;

        @include media("<mobile") {
            overflow-x: auto;
            padding: 0 $indent-mobile;
        }

        > * + * {
            margin-left: rem(6);

            @include media("<mobile") {
                margin-left: mobile(10);
            }
        }
    }

    .rs-button {
        padding-left: rem(12);
        padding-right: rem(12);
        border-radius: rem(20);
        white-space: nowrap;

        @include media("<mobile") {
            padding-left: mobile(24);
            padding-right: mobile(24);
            border-radius: mobile(60);
        }

        .rs-icon {
            margin-right: rem(4);
            font-size: rem(14);

            @include media("<mobile") {
                margin-right: mobile(10);
            }
        }
    }
}
