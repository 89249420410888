@import "styles/utils";

.common-grade-badge {
    @include grade-badge-text-color;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    font-size: rem(13);

    @include media("<mobile") {
        font-size: mobile(24);
    }

    &.size-s {
        @include media(">=mobile") {
            font-size: rem(12);
        }

        img {
            @include media(">=mobile") {
                width: rem(14);
                height: rem(14);
            }
        }

        p {
            @include media(">=mobile") {
                margin-left: rem(3);
            }
        }
    }

    &.size-l {
        @include media(">=mobile") {
            font-size: rem(18);
        }

        img {
            @include media(">=mobile") {
                width: rem(24);
                height: rem(24);
            }
        }
    }

    &.m-size-s {
        @include media("<mobile") {
            font-size: mobile(26);
        }

        img {
            @include media("<mobile") {
                width: mobile(30);
                height: mobile(30);
            }
        }
    }

    &.m-size-l {
        @include media("<mobile") {
            font-size: mobile(32);
        }

        img {
            @include media("<mobile") {
                width: mobile(40);
                height: mobile(40);
            }
        }
    }

    &.m-size-xl {
        @include media("<mobile") {
            font-size: mobile(32);
        }

        img {
            @include media("<mobile") {
                width: mobile(44);
                height: mobile(44);
            }
        }
    }

    &.theme1 {
        display: inline-flex;
        align-items: center;
        padding: rem(5) rem(8) rem(5) rem(7);
        border: rem(1) solid #d8deef;
        border-radius: rem(20);
        font-weight: $font-weight-medium;
        @include grade-badge-theme1;

        @include media("<mobile") {
            padding: mobile(6) mobile(12) mobile(6) mobile(6);
            border-width: mobile(1);
            border-radius: mobile(40);
        }
    }

    img {
        width: rem(20);
        height: rem(20);

        @include media("<mobile") {
            width: mobile(34);
            height: mobile(34);
        }

        &.black {
            transform: scale(0.9);
        }
    }

    p {
        margin-left: rem(4);

        @include media("<mobile") {
            margin-left: mobile(5);
        }
    }
}
