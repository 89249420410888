@import "styles/utils";

@keyframes moveLeftAnimation {
    from {
        transform: translateX(50%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes moveRightAnimation {
    from {
        transform: translateX(-50%);
    }
    to {
        transform: translateX(0);
    }
}

#messenger-side-content {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    width: rem(324);
    border-right: rem(1) solid $gray-1;

    @include media("<wide-web", ">=mobile") {
        width: rem(306);
    }

    @include media("<mobile") {
        width: 50%;
        border-right: none;
        transition: transform 0.3s;
    }

    .messenger-side-content-wrapper {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: hidden;
        height: 100%;

        .messenger-side-list-items {
            display: flex;
            overflow: hidden;
            flex: 1;
            width: 200%;
            // transition: margin-left 0.3s ease;
            &.active {
                margin-left: -100%;
                animation: moveLeftAnimation 0.3s ease;
            }

            &.inactive {
                animation: moveRightAnimation 0.3s ease;
            }
        }

        .messenger-side-list-item {
            display: flex;
            flex-direction: column;
            flex: 1;
            overflow-y: auto;
        }

        .messenger-side-list-item-wrapper {
            display: flex;
            flex-direction: column;
            overflow-y: auto;
            height: 100%;
        }
    }
}
