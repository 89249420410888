@import "styles/utils";

.message-box-to-list-name {
    display: flex;
    align-items: center;
    max-width: 100%;
    font-size: rem(16);
    font-weight: $font-weight-medium;
    cursor: pointer;

    @include media("<mobile") {
        font-size: mobile(32);
        color: $gray-4;
    }

    &:not(:focus-within) {
        & ~ .to-list {
            display: none;
        }

        .icon-wrapper {
            transform: rotate(180deg);
        }
    }

    .icon-wrapper {
        margin-left: rem(4);
        font-size: rem(10);

        @include media("<mobile") {
            margin-left: mobile(8);
            font-size: mobile(20);
        }
    }

    h3 {
        @include ellipsis;
    }

    .open-brand-history-btn {
        display: flex;
        align-items: center;
        flex: 1;
        overflow: hidden;
        cursor: pointer;

        .rs-icon {
            margin-top: rem(2);
            margin-left: rem(2);
            margin-right: rem(6);
            font-size: rem(8);

            @include media("<mobile") {
                margin-top: 0;
                margin-left: mobile(4);
                margin-right: mobile(12);
                font-size: mobile(20);
            }
        }
    }
}
