@import "styles/utils";

.messenger-warning-box {
    font-size: rem(12);
    background-color: $red-lighter;
    color: $red;

    @include media("<mobile") {
        font-size: mobile(24);
    }

    &.list {
        padding: rem(10) $messenger-indent;
        border-bottom: rem(1) solid $gray-1;

        @include media("<mobile") {
            display: flex;
            padding: mobile(20) $indent-mobile;
            border-bottom-width: mobile(1);
            line-height: 1.58;
        }

        &:not(.more) {
            .text-area {
                @include media("<mobile") {
                    @include multi-line-overflow(2);
                }
            }
        }
    }

    &.write {
        margin-top: rem(18);
        padding: rem(14);
        border: rem(1) solid $red-light;
        border-radius: rem(4);
        text-align: center;
        white-space: pre-wrap;

        @include media("<mobile") {
            margin: mobile(40) $indent-mobile 0;
            padding: mobile(20) $indent-mobile;
            border-width: mobile(1);
            border-radius: mobile(6);
            white-space: normal;
        }

        a {
            display: inline-block;
            margin-top: rem(8);
            font-weight: $font-weight-medium;

            @include media("<mobile") {
                margin-top: mobile(14);
            }
        }

        .rs-icon {
            font-size: rem(10);

            @include media("<mobile") {
                font-size: mobile(22);
            }
        }
    }

    p {
        margin-bottom: rem(8);
        font-weight: $font-weight-bold;

        @include media("<mobile") {
            margin-bottom: mobile(14);
        }
    }

    a {
        font-weight: $font-weight-medium;
        text-decoration: underline;
    }

    .more-btn {
        @include only-mobile;
        margin-left: mobile(68);
        font-size: mobile(30);
        color: $red-light;
    }
}
