@import "styles/utils";

.messenger-main-brand-history {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
    padding-left: $messenger-indent;
    padding-right: $messenger-indent;

    @include media("<mobile") {
        padding-top: mobile(50);
    }

    .reviewer-info {
        display: flex;
        align-items: center;

        @include media("<mobile") {
            flex-direction: column;
        }

        .rs-profile {
            margin-right: rem(10);

            @include media("<mobile") {
                margin-right: 0;
                margin-bottom: mobile(13);
            }
        }

        > div {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 1;
            overflow: hidden;

            @include media("<mobile") {
                align-items: center;
                width: 100%;
            }

            > * {
                flex-shrink: 0;
            }
        }
    }

    .reviewer-info,
    .brand-info {
        margin: rem(4) 0 rem(15);

        @include media("<mobile") {
            margin: 0 0 mobile(40);
        }
    }

    .reviewer-nickname,
    .brand-name {
        font-weight: $font-weight-medium;

        @include media("<mobile") {
            font-size: mobile(36);
            color: $gray-5;
        }
    }

    .reviewer-nickname {
        @include ellipsis;
        width: 100%;

        @include media("<mobile") {
            width: 100%;
            text-align: center;
        }
    }

    .brand-history-counts {
        margin: 0 (-$messenger-indent);
        padding: 0 $messenger-indent rem(24);
        border-bottom: rem(1) solid $gray-1;

        @include media("<mobile") {
            margin: 0 (-$indent-mobile);
            padding: 0 $indent-mobile mobile(60);
            border-bottom-width: mobile(1);
        }
    }

    .brand-history-counts-item {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .item-title {
            font-size: rem(14);
            color: $gray-3;

            @include media("<mobile") {
                font-size: mobile(28);
            }
        }

        .item-counts-text {
            display: flex;
            align-items: center;
            font-size: rem(13);
            white-space: pre-wrap;
            color: $gray-2;

            @include media("<mobile") {
                font-size: mobile(28);
            }

            .item-counts {
                font-size: rem(20);
                font-weight: $font-weight-bold;
                color: $gray-5;

                @include media("<mobile") {
                    font-size: mobile(40);
                }
            }
        }
    }

    .brand-history-buttons {
        margin-top: rem(24);

        @include media("<mobile") {
            margin-top: mobile(60);
        }
    }

    .common-relation-status-btn {
        margin-top: rem(4);
        padding-left: rem(8);
        padding-right: rem(8);

        @include media("<mobile") {
            margin-top: mobile(12);
            padding-left: mobile(40);
            padding-right: mobile(40);
        }
    }
}
