@import "styles/utils";

.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: rem(120) 0;

    @include media("<mobile") {
        margin: mobile(240) 0;
    }
}

.inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: rem(514);

    @include media("<mobile") {
        width: 100%;
    }

    > * {
        flex-shrink: 0;
    }
}

.img {
    display: block;
    width: rem(142);
    object-fit: cover;

    @include media("<mobile") {
        width: mobile(218);
    }
}

.title {
    margin-top: rem(18);
    font-size: rem(28);
    font-weight: $font-weight-medium;
    color: $gray-5;

    @include media("<mobile") {
        margin-top: mobile(24);
        font-size: mobile(44);
        white-space: pre-wrap;
    }
}

.desc {
    margin-top: rem(4);
    font-size: rem(16);
    white-space: pre-wrap;
    color: $gray-5;

    @include media("<mobile") {
        margin-top: mobile(12);
        font-size: mobile(26);
        font-weight: $font-weight-medium;
    }
}

.link {
    width: rem(130);
    margin-top: rem(28);
    box-shadow: 0 rem(3) rem(6) 0 rgba($black, 0.2);

    @include media("<mobile") {
        width: mobile(220);
        margin-top: mobile(40);
        box-shadow: 0 mobile(3) mobile(6) 0 rgba($black, 0.2);
    }

    @include global {
        .rs-icon {
            margin-right: rem(6);

            @include media("<mobile") {
                margin-right: mobile(12);
            }
        }
    }
}

.cs {
    width: 100%;
    margin-top: rem(32);
    padding-top: rem(32);
    border-top: rem(2) dashed $gray-1;
    font-size: rem(14);
    line-height: 1.7;
    white-space: pre-wrap;
    color: $gray-3;

    @include media("<mobile") {
        margin-top: mobile(60);
        padding-top: mobile(60);
        border-top-width: mobile(2);
        font-size: mobile(26);
        line-height: 1.6;
    }

    a {
        @include link-style;
    }
}

.errorBox {
    width: 100%;
    margin-top: rem(24);
    border-radius: rem(6);
    background-color: $gray-0;

    @include media("<mobile") {
        margin-top: mobile(40);
        border-radius: mobile(6);
    }
}

.errorMoreBtn {
    width: 100%;

    @include global {
        .rs-icon {
            margin-left: rem(4);

            @include media("<mobile") {
                margin-left: mobile(8);
            }
        }
    }
}

.errorCode {
    padding: rem(10) rem(20) rem(20);
    border-top: rem(1) solid $gray-1;
    font-size: rem(14);
    text-align: left;

    @include media("<mobile") {
        padding: mobile(20) mobile(30) mobile(30);
        border-top-width: mobile(1);
        font-size: mobile(26);
    }

    &:not(.active) {
        display: none;
    }
}
