@import "styles/utils";

.completeModal {
    text-align: center;

    @include media(">=mobile") {
        width: rem(400);
    }

    .modalInner {
        display: flex;
        flex-direction: column;
        align-items: center;

        > * {
            flex-shrink: 0;
        }
    }
}

.tag {
    margin-top: rem(8);

    @include media("<mobile") {
        margin-top: mobile(14);
    }

    @include global {
        .rs-icon {
            margin-right: rem(3);

            @include media("<mobile") {
                margin-right: mobile(4);
            }
        }
    }
}

.completeMsg {
    margin-top: rem(16);
    margin-bottom: rem(10);
    font-size: rem(16);
    font-weight: $font-weight-medium;
    color: $gray-5;

    @include media("<mobile") {
        margin-top: mobile(36);
        margin-bottom: mobile(16);
        font-size: mobile(28);
    }
}

.link {
    @include btn-clear-style;
    font-size: rem(14);
    font-weight: $font-weight-medium;
    color: $main-color;

    @include media("<mobile") {
        font-size: mobile(28);
    }

    &:hover {
        text-decoration: underline;
    }

    @include global {
        .rs-icon {
            margin-bottom: rem(1);
            font-size: rem(10);

            @include media("<mobile") {
                font-size: mobiel(20);
            }
        }
    }
}
