@import "styles/utils";

.modal {
    position: relative;

    @include media(">=mobile") {
        overflow: hidden;
        max-height: rem(440);
        padding-right: 0;
    }

    img {
        display: block;
    }

    @include global {
        .rs-standard-modal-content {
            @include media("<mobile") {
                padding-top: 0 !important;
                padding-left: 0 !important;
                padding-right: 0 !important;
            }
        }
    }

    @include global {
        .rs-more-menu {
            @include media(">=mobile") {
                margin-right: rem(18);
            }

            @include media("<mobile") {
                margin-left: mobile(-10);
                font-size: mobile(32);
            }
        }
    }
}

.wrapper {
    display: flex;
    position: relative;

    @include media(">=mobile") {
        height: 100%;
    }

    @include media("<mobile") {
        flex-direction: column;
        margin: 0 0 mobile(10);
    }
}

.info-area {
    width: rem(354);
    margin-left: rem(30);

    @include media(">=mobile") {
        overflow-y: auto;
        max-height: rem(410);
        padding-bottom: rem(30);
        padding-right: $standard-modal-indent;
    }

    @include media("<mobile") {
        width: 100%;
        margin-top: mobile(60);
        margin-left: 0;
        padding: 0 $indent-mobile;
    }
}

.image-area {
    flex-shrink: 0;
    width: rem(340);

    @include media("<mobile") {
        width: mobile(720);
    }
}

.content-area {
    margin-top: rem(8);

    @include media("<mobile") {
        margin-top: mobile(24);
    }
}

.feed-title {
    color: $gray-3;
    white-space: pre-wrap;
    word-break: break-all;

    @include media("<mobile") {
        font-size: mobile(26);
    }

    & + .feed-preview-list {
        margin-top: rem(12);

        @include media("<mobile") {
            margin-top: mobile(8);
        }
    }
}

.content-title {
    margin-bottom: rem(6);
    font-size: rem(12);
    font-weight: $font-weight-medium;

    @include media("<mobile") {
        margin-bottom: mobile(12);
        font-size: mobile(22);
    }
}

.user-profile {
    display: flex;
    align-items: center;

    p {
        font-weight: $font-weight-bold;

        @include media("<mobile") {
            font-size: mobile(32);
            color: $gray-5;
        }

        &.skeleton {
            width: 50%;
        }
    }

    @include global {
        .rs-profile {
            margin-right: rem(12);

            @include media("<mobile") {
                margin-right: mobile(16);
            }
        }
    }
}

.sns-icon {
    display: block;
    position: absolute;
    top: rem(14);
    right: rem(14);
    width: rem(24);
    height: rem(24);

    @include media("<mobile") {
        top: mobile(14);
        right: mobile(8);
        width: mobile(40);
        height: mobile(40);
    }
}

.more-link {
    margin-top: rem(8);

    @include media(">=mobile") {
        border-radius: rem(60);
    }

    @include media("<mobile") {
        margin-top: 0;
        border-radius: 0;
    }
}

.pagination-box {
    position: absolute;
    top: rem(20);
    right: rem(12);
    padding: rem(7) rem(14);
    border-radius: rem(20);
    font-size: rem(12);
    font-weight: $font-weight-medium;
    background-color: rgba(0, 0, 0, 0.7);
    color: $gray-0;

    @include media("<mobile") {
        top: mobile(30);
        right: mobile(10);
        padding: mobile(18) mobile(30);
        border-radius: mobile(60);
        font-size: mobile(24);
    }

    .pagination-length {
        color: $gray-2;
    }
}

.created-at {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: rem(8);
    font-size: rem(12);
    color: $gray-2;

    @include media("<mobile") {
        margin-top: mobile(12);
        font-size: mobile(22);
    }
}

.lock-status {
    display: inline-flex;
    align-items: center;
    white-space: pre-wrap;

    &:after {
        content: "";
        display: block;
        width: rem(1);
        height: rem(10);
        margin: 0 rem(5);
        background-color: $gray-1;

        @include media("<mobile") {
            width: mobile(1);
            height: mobile(16);
            margin: 0 mobile(8);
        }
    }

    @include global {
        .rs-icon {
            font-size: rem(10);

            @include media("<mobile") {
                font-size: mobile(22);
            }
        }
    }
}

.action-items {
    @include only-web;
    position: absolute;
    top: $standard-modal-top-padding;
    right: $standard-modal-indent;
    z-index: 1;
    font-size: rem(18);
    color: $gray-3;

    .close-btn {
        @include btn-clear-style;
    }
}

.profile-link {
    display: inline-block;
}

.feed-image-wrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: rem(340);
    border-radius: rem(10);
    background-color: $gray-0;

    @include media("<mobile") {
        width: mobile(720);
        height: mobile(720);
        border-radius: 0;
    }

    &,
    .feed-swiper > div > div,
    .feed-image,
    .skeleton {
        @include media(">=mobile") {
            width: rem(340) !important;
            height: rem(340);
        }

        @include media("<mobile") {
            width: mobile(720) !important;
            height: mobile(720);
        }
    }
}

img.feed-image {
    object-fit: cover;
}

video.feed-image {
    object-fit: contain;
    background-color: $black;
}

.feed-preview-list {
    display: flex;
    flex-wrap: wrap;
    margin: rem(-3);

    @include media("<mobile") {
        margin: mobile(-5);
    }
}

.feed-preview-item {
    position: relative;
    overflow: hidden;
    width: rem(54);
    height: rem(54);
    margin: rem(3);
    border: rem(2) solid transparent;
    border-radius: rem(4);

    @include media("<mobile") {
        width: mobile(100);
        height: mobile(100);
        margin: mobile(5);
        border-width: mobile(4);
        border-radius: mobile(6);
    }

    &.active {
        border-color: $main-color;
    }

    &:not(.active):hover {
        border-color: rgba($main-color, 0.2);
    }

    &:before {
        content: "";
        display: block;
        padding-bottom: 100%;
    }

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        background-color: $gray-0;
    }
}

.duration-background {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: rem(3);
    font-size: rem(10);
    background-color: rgba($black, 0.2);
    color: $white;

    @include media("<mobile") {
        padding: mobile(8);
        font-size: mobile(22);
    }
}

.slider-controls {
    @include only-web;

    button {
        @include btn-clear-style;
        position: absolute;
        top: 50%;
        width: rem(24);
        height: rem(24);
        border-radius: 50%;
        font-size: rem(10);
        transform: translateY(-50%);
        background-color: $black;

        &.prev-btn {
            left: rem(12);
        }

        &.next-btn {
            right: rem(12);
        }

        @include global {
            .rs-icon {
                color: $white;
            }
        }
    }
}

.service-info {
    margin-top: rem(30);

    @include media("<mobile") {
        margin-top: mobile(50);
    }
}

.service-link {
    display: flex;
    align-items: center;
    padding: rem(20);
    border: rem(1) solid $gray-1;
    border-radius: rem(10);

    @include media("<mobile") {
        padding: mobile(30);
        border-width: mobile(1);
        border-radius: mobile(10);
    }

    img {
        width: rem(64);
        height: rem(64);
        margin-right: rem(14);
        border-radius: rem(4);

        @include media("<mobile") {
            width: mobile(140);
            height: mobile(140);
            margin-right: mobile(24);
            border-radius: mobile(10);
        }
    }
}

.service-title {
    @include multi-line-overflow(2);
    margin-bottom: rem(3);
    font-weight: $font-weight-medium;

    @include media("<mobile") {
        margin-bottom: mobile(6);
    }
}

.service-text {
    display: flex;
    font-size: rem(12);
    color: $gray-2;

    @include media("<mobile") {
        font-size: mobile(24);
    }

    span {
        display: flex;
        align-items: center;
    }

    span + span:before {
        content: "";
        display: block;
        width: rem(2);
        height: rem(2);
        margin: 0 rem(3);
        border-radius: 50%;
        background-color: $gray-2;

        @include media("<mobile") {
            width: mobile(4);
            height: mobile(4);
            margin: 0 mobile(4);
        }
    }
}
