@import "styles/utils";

.btn {
    flex-shrink: 0;
    flex-basis: auto;
    position: relative;
}

.btn {
    &.line {
        padding-left: rem(6);
        padding-right: rem(6);

        @include media("<mobile") {
            padding-left: mobile(16);
            padding-right: mobile(16);
        }

        &:not(:hover) {
            background-color: transparent;
        }
    }

    &.clear {
        color: $gray-4;

        @include media(">=mobile") {
            opacity: 0.7;
        }

        &:hover {
            @include media(">=mobile") {
                opacity: 1;
                text-decoration: underline;
            }
        }

        &:global {
            &.block {
                color: $gray-3;
            }
        }
    }
}

.spin {
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0.6);

    & + span {
        visibility: hidden;
    }
}
