@import "styles/utils";

.biz-intro-ad-banner {
    display: flex;
    height: rem(72);
    background-color: #489bff;

    @include media("<mobile") {
        height: mobile(160);
    }

    img {
        display: block;
        margin: 0 auto;
        object-fit: cover;
        height: 100%;
    }

    .ad-banner-wrapper {
        @include wide-web-width-limit;

        @include media("<mobile") {
            width: 100%;
        }

        .inner {
            position: relative;
            height: 100%;
        }
    }

    .rs-icon {
        position: absolute;
        top: 50%;
        right: rem(-10);
        padding: rem(10);
        transform: translateY(-50%);
        color: $main-color;
        cursor: pointer;

        @include media("<mobile") {
            right: $indent-mobile - mobile(10);
            padding: mobile(10);
            font-size: mobile(30);
        }
    }
}
