@import "styles/utils";

.itemTitle {
    margin-top: rem(24);

    @include media("<mobile") {
        margin-top: mobile(40);
    }
}

.cautionText {
    margin-top: rem(6);
    font-size: rem(12);
    color: $gray-3;

    @include media("<mobile") {
        margin-top: mobile(20);
        font-size: mobile(22);
    }
}

.dropdown {
    color: $gray-5;

    & + .dropdown {
        margin-top: rem(12);

        @include media("<mobile") {
            margin-top: mobile(20);
        }
    }
}

.itemName {
    font-size: rem(14);
    font-weight: $font-weight-medium;

    @include media("<mobile") {
        font-size: mobile(28);
    }
}

.createdAt {
    margin-top: rem(4);
    font-size: rem(12);
    color: $gray-2;

    @include media("<mobile") {
        margin-top: mobile(6);
        font-size: mobile(24);
    }
}
