@import "styles/utils";

#modal {
    @include media(">=mobile") {
        width: rem(600);
        height: 100vh;
    }

    &.step-2 {
        @include global {
            .rs-standard-modal-content {
                @include media("<mobile") {
                    height: 100%;
                }
            }
        }
    }

    @include global {
        .rs-standard-modal-content {
            padding-bottom: rem(60);

            @include media("<mobile") {
                padding-bottom: mobile(100);
            }
        }

        .rs-standard-modal-footer {
            padding: 0;

            .rs-button {
                border-radius: 0;
            }
        }
    }
}

.modalInner {
    padding-top: rem(16);

    @include media("<mobile") {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding-top: mobile(20);
    }

    > * {
        @include media("<mobile") {
            flex-shrink: 0;
        }
    }
}

.stepTitle {
    margin-bottom: rem(14);
    font-size: rem(24);
    font-weight: $font-weight-bold;
    color: $gray-5;

    @include media("<mobile") {
        margin-bottom: mobile(20);
        font-size: mobile(36);
    }
}

.item {
    & + .item {
        margin-top: rem(40);

        @include media("<mobile") {
            margin-top: mobile(70);
        }
    }
}

.itemTitle {
    font-size: rem(18);
    font-weight: $font-weight-medium;
    color: $gray-5;

    @include media("<mobile") {
        font-size: mobile(28);
    }
}

.itemContent {
    margin-top: rem(14);

    @include media("<mobile") {
        margin-top: mobile(40);
    }
}

.infoBox {
    margin-top: rem(14);

    @include media("<mobile") {
        margin-top: mobile(20);
    }
}

.cautionList {
    margin-top: rem(40);
    padding-top: rem(40);
    border-top: rem(1) solid $gray-1;

    @include media("<mobile") {
        margin-top: mobile(70);
        padding-top: mobile(70);
        border-top-width: mobile(1);
    }
}

.errorMsg {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: rem(4);
    font-size: rem(12);
    color: $red;

    @include media("<mobile") {
        margin-top: mobile(6);
        font-size: mobile(24);
    }

    @include global {
        .rs-icon {
            margin-right: rem(4);

            @include media("<mobile") {
                margin-right: mobile(4);
            }
        }
    }
}
