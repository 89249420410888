@import "styles/utils";

.induce-subscribe-about-fee {
    &:not(.active) {
        .tip-desc {
            display: none;
        }
    }

    .icon_tip {
        font-size: rem(24);
        color: $orange-darker;

        @include media("<mobile") {
            font-size: mobile(44);
        }
    }

    .tip-title {
        display: inline-flex;
        align-items: center;
        font-weight: $font-weight-medium;
        white-space: pre-wrap;
        color: $gray-5;
    }

    .tip-desc {
        margin-top: rem(12);
        color: $gray-3;

        @include media("<mobile") {
            margin-top: mobile(20);
        }

        p {
            @include media("<mobile") {
                line-height: 1.6;
            }
        }
    }

    .icon_arrow_up_circle,
    .icon_arrow_down_circle {
        margin-left: rem(6);
        font-size: rem(15);

        @include media("<mobile") {
            margin-left: mobile(12);
            font-size: mobile(28);
        }
    }

    .subscribe-btn {
        @include btn-clear-style;
        margin-top: rem(10);
        color: $main-color;

        @include media("<mobile") {
            margin-top: mobile(16);
        }

        .rs-icon {
            margin-left: rem(2);
            font-size: rem(8);

            @include media("<mobile") {
                margin-left: 0;
                font-size: mobile(18);
            }
        }
    }
}

.induce-subscribe-about-fee.project {
    margin-top: rem(12);
    font-size: rem(14);

    @include media("<mobile") {
        margin-top: mobile(24);
        font-size: mobile(26);
    }

    .tip-title {
        box-shadow: inset 0 rem(-10) 0 0 rgba($orange-darker, 0.1);

        @include media("<mobile") {
            box-shadow: inset 0 mobile(-20) 0 0 rgba($orange-darker, 0.1);
        }
    }

    .tip-desc {
        padding: rem(10) rem(20);
        border: rem(1) solid $blue-lighter;
        border-radius: rem(4);
        background-color: $blue-lightest;

        @include media("<mobile") {
            padding: mobile(24);
            border-width: mobile(1);
            border-radius: mobile(6);
        }
    }

    .icon_arrow_up_circle,
    .icon_arrow_down_circle {
        font-size: rem(15);

        @include media("<mobile") {
            font-size: mobile(28);
        }
    }
}

.induce-subscribe-about-fee.suggestion {
    margin-top: rem(20);
    padding: rem(10) rem(14);
    border: rem(1) solid $blue-lighter;
    border-radius: rem(4);
    font-size: rem(12);
    background-color: $blue-lightest;

    @include media("<mobile") {
        margin-top: rem(32);
        padding: mobile(24);
        border-width: mobile(1);
        border-radius: mobile(6);
        font-size: mobile(24);
    }

    .icon_arrow_up_circle,
    .icon_arrow_down_circle {
        font-size: rem(13);

        @include media("<mobile") {
            font-size: mobile(28);
        }
    }
}
