@import "styles/utils";

.global-footer {
    padding-bottom: rem(70);

    @include media("<mobile") {
        padding-bottom: mobile(100);
    }

    .footer-wrapper {
        display: flex;
        justify-content: space-between;
        max-width: $width-xl;
        margin: 0 auto;

        @include media("<wide-web") {
            padding: 0 $indent-wide-web;
            max-width: $width-l;
        }

        @include media("<mobile") {
            flex-direction: column;
            padding: 0 $indent-mobile;
        }
    }

    // only web
    .service-info {
        padding: rem(50) 0;
        border-top: rem(1) solid $gray-1;
        border-bottom: rem(1) solid $gray-1;
    }

    .cs-center-info {
        .cs-center-title {
            font-size: rem(36);
            font-weight: $font-weight-bold;
            line-height: 1.5;
        }

        .cs-center-date {
            margin-top: rem(6);
            font-size: rem(12);
            line-height: 1.5;

            span {
                display: block;
                margin-top: rem(3);
                color: $gray-2;
            }
        }

        .cs-center-menu-itmes {
            display: flex;
            align-items: center;
            margin-top: rem(10);

            .rs-button {
                padding-left: rem(10);
                padding-right: rem(10);

                & + .rs-button {
                    margin-left: rem(6);
                }
            }

            .rs-icon {
                margin-left: rem(6);
                font-size: rem(8);
                color: $gray-2;
            }
        }
    }

    .site-menu-list {
        display: flex;
        font-size: rem(14);

        li {
            display: flex;
            flex-direction: column;
            margin-right: rem(60);

            @include media("<wide-web") {
                margin-right: rem(40);
            }
        }

        p {
            margin-bottom: rem(14);
            font-weight: $font-weight-medium;
        }

        a {
            color: $gray-3;

            &:hover {
                text-decoration: underline;
            }

            & + a {
                margin-top: rem(8);
            }
        }
    }

    .outside-link-list {
        display: flex;
        align-items: center;

        @include media("<mobile") {
            margin-top: mobile(60);
        }

        li {
            & + li {
                margin-left: rem(8);

                @include media("<mobile") {
                    margin-left: mobile(20);
                }
            }
        }

        a {
            display: flex;
            justify-content: center;
            align-items: center;
            width: rem(38);
            height: rem(38);
            border-radius: 50%;
            background-color: $gray-0;
            color: $gray-3;

            @include media("<mobile") {
                width: mobile(78);
                height: mobile(78);
            }
        }

        .rs-icon {
            font-size: rem(17);

            @include media("<mobile") {
                font-size: mobile(40);
            }

            &.icon_blog {
                font-size: rem(20);

                @include media("<mobile") {
                    font-size: mobile(44);
                }
            }
        }
    }

    .business-info {
        @include media("<mobile") {
            border-top: mobile(1) solid $gray-1;
        }

        .menu-items {
            display: flex;
            align-items: center;
            margin-top: rem(20);

            @include media("<mobile") {
                margin-top: mobile(60);
            }

            li {
                display: flex;
                align-items: center;
                font-size: rem(14);
                font-weight: $font-weight-medium;

                @include media("<mobile") {
                    font-size: mobile(22);
                }

                & + li {
                    &:before {
                        content: "";
                        display: block;
                        width: rem(1);
                        height: rem(10);
                        margin: 0 rem(6);
                        background-color: $gray-1;

                        @include media("<mobile") {
                            width: mobile(1);
                            height: mobile(20);
                            margin: 0 mobile(14);
                        }
                    }
                }
            }
        }

        .company-info {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            max-width: rem(930);
            margin: rem(16) rem(-5) 0;
            font-size: rem(12);
            color: $gray-3;

            @include media("<mobile") {
                flex-direction: column;
                align-items: flex-start;
                max-width: inherit;
                margin: mobile(40) 0 0;
                font-size: mobile(22);
                color: $gray-2;
            }

            li {
                margin: 0 rem(5);

                @include media("<mobile") {
                    margin: mobile(4) 0;
                }

                span {
                    @include media("<mobile") {
                        color: $gray-3;
                    }
                }
            }
        }

        .copyright {
            margin-top: rem(10);
            font-size: rem(12);

            @include media("<mobile") {
                margin-top: mobile(24);
                font-size: mobile(22);
            }
        }
    }
}
