@import "styles/utils";

.tag {
    white-space: pre-wrap;

    @include global {
        .rs-icon {
            margin-right: rem(3);

            @include media("<mobile") {
                margin-right: mobile(4);
            }
        }
    }
}
