@mixin ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin link-style {
    color: $main-color;
    text-decoration: underline;
    word-break: break-all;

    &:hover {
        color: $active-color;
    }
}

@mixin dot-style($backgroundColor: $gray-3) {
    display: block;
    width: rem(4);
    height: rem(4);
    margin: 0 rem(4);
    border-radius: 50%;
    background-color: $backgroundColor;

    @include media("<mobile") {
        width: mobile(4);
        height: mobile(4);
        margin: 0 mobile(12);
    }
}

@mixin only-web() {
    @include media("<mobile") {
        display: none !important;
    }
}

@mixin only-mobile() {
    @include media(">=mobile") {
        display: none !important;
    }
}

@mixin hide-lang($lang) {
    @if ($lang == "kr") {
        .lang-kr & {
            display: none !important;
        }
    }
    @if ($lang == "en") {
        .lang-en & {
            display: none !important;
        }
    }
    @if ($lang == "vn") {
        .lang-vn & {
            display: none !important;
        }
    }
}

@mixin multi-line-overflow($line) {
    word-wrap: break-word;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
}

@mixin modal-title() {
    font-size: rem(20);
    font-weight: $font-weight-medium;
    text-align: center;
    white-space: pre-wrap;
    line-height: 1.5;

    @include media("<mobile") {
        font-size: mobile(36);
        color: $gray-4;
    }
}

@mixin modal-text-area() {
    margin-top: rem(12);
    font-size: rem(14);
    line-height: 1.5;
    text-align: center;
    white-space: pre-wrap;
    color: $gray-3;

    @include media("<mobile") {
        margin-top: mobile(30);
        font-size: mobile(24);
        line-height: 1.67;
    }

    p {
        line-height: inherit;
    }
}

@mixin required-mark() {
    display: flex;
    align-items: center;

    &:after {
        content: "";
        display: inline-block;
        width: rem(6);
        height: rem(6);
        margin-left: rem(6);
        border-radius: 50%;
        background-color: $main-color;

        @include media("<mobile") {
            width: mobile(8);
            height: mobile(8);
            margin-left: mobile(6);
        }
    }
}

@mixin unread-mark($position: static) {
    @if ($position == "absolute") {
        position: relative;
    }

    &:after {
        content: "";
        display: inline-block;
        flex-shrink: 0;
        position: $position;
        top: 0;
        right: 0;
        width: rem(5);
        height: rem(5);
        margin: rem(2) rem(-5) 0 rem(2);
        border-radius: 50%;
        vertical-align: top;
        background-color: $red;

        @include media("<mobile") {
            width: mobile(10);
            height: mobile(10);
            margin: 0 mobile(-10) 0 mobile(4);
        }
    }
}

@mixin wide-web-width-limit($size: $width-l) {
    width: 100%;
    max-width: $size;
    margin: 0 auto;

    @include media("<wide-web", ">=mobile") {
        max-width: $width-l;
        padding-left: $indent-wide-web;
        padding-right: $indent-wide-web;
    }

    @include media("<mobile") {
        width: auto;
    }
}

@mixin web-width-l-limit() {
    @include media(">=mobile") {
        width: $width-l;
        margin-left: auto;
        margin-right: auto;
        padding-left: $indent-wide-web;
        padding-right: $indent-wide-web;
    }
}

@mixin grade-badge-theme1 {
    &.z {
        border-color: #ffa69b;

        &:hover {
            background-color: #ffefed;
        }
    }

    &.a {
        border-color: #d8deef;

        &:hover {
            background-color: #f5f8ff;
        }
    }

    &.b {
        border-color: #cbd0d8;

        &:hover {
            background-color: #f7f7fa;
        }
    }

    &.c {
        border-color: #f7d562;

        &:hover {
            background-color: #fff8e2;
        }
    }

    &.d {
        border-color: #a9ebe0;

        &:hover {
            background-color: #e8fffb;
        }
    }
}

@mixin grade-badge-text-color() {
    &.z {
        color: $red;
    }

    &.a {
        color: #6d799a;
    }

    &.b {
        color: #93959a;
    }

    &.c {
        color: #ce9b00;
    }

    &.d {
        color: #00b6be;
    }
}

@mixin editor-text-style($size: "m") {
    line-height: 1.7;
    letter-spacing: rem(-0.8);
    white-space: pre-wrap;
    word-break: keep-all;
    color: $gray-3;

    @if ($size == "s") {
        font-size: rem(14);

        @include media("<mobile") {
            font-size: mobile(22);
        }
    } @else {
        font-size: rem(16);

        @include media("<mobile") {
            font-size: mobile(24);
        }
    }

    @include media("<mobile") {
        letter-spacing: mobile(-0.8);
    }

    p {
        line-height: 1.7;
    }

    a {
        @include link-style;
    }

    strong {
        font-weight: $font-weight-medium;
    }

    h3 {
        font-weight: $font-weight-normal;
        line-height: 1.7;

        @if ($size == "s") {
            font-size: rem(16);

            @include media("<mobile") {
                font-size: mobile(24);
            }
        } @else {
            font-size: rem(18);

            @include media("<mobile") {
                font-size: mobile(26);
            }
        }
    }

    img,
    video {
        display: block;
        max-width: 100%;
    }

    ul {
        li {
            list-style: disc;
        }

        li::marker {
            @include media("<mobile") {
                font-size: mobile(20);
            }
        }
    }

    ul,
    ol {
        padding-left: 0;

        li {
            padding-left: 0;
            line-height: 1.7;
            list-style-position: inside;
        }

        li::before {
            content: "";
            display: none;
        }
    }

    ol {
        list-style: auto;

        li {
            list-style-type: decimal;
        }
    }

    hr {
        border: none;
        border-top: rem(1) solid $gray-1;

        @include media("<mobile") {
            border-top-width: mobile(1);
        }
    }

    pre.ql-syntax {
        display: inline-block;
        max-width: 100%;
        margin: 0;
        padding: rem(20);
        border: rem(1) solid $gray-1;
        border-radius: rem(10);
        background-color: $gray-0;
        white-space: pre-wrap;
        word-break: break-all;
        color: inherit;

        @include media("<mobile") {
            padding: mobile(20) $indent-mobile;
            border-radius: mobile(20);
        }
    }

    blockquote {
        margin: 0;
        padding-left: rem(12);
        border-left: rem(4) solid $gray-1;
        color: $gray-5;

        @include media("<mobile") {
            padding-left: mobile(20);
            border-left-width: mobile(4);
        }
    }

    .ql-indent-1 {
        &:not(.ql-direction-rtl) {
            padding-left: rem(20);

            @include media("<mobile") {
                padding-left: $indent-mobile;
            }
        }
    }
}

@mixin caution-style {
    padding: rem(20);
    background-color: $gray-0;
    border-radius: rem(4);

    @include media("<mobile") {
        padding: mobile(24) mobile(36);

        border-radius: mobile(6);
    }

    .rs-caution-title {
        margin-bottom: rem(8);

        @include media("<mobile") {
            margin-bottom: mobile(20);
        }
    }

    .rs-caution-text + .rs-caution-text {
        margin-top: rem(4);

        @include media("<mobile") {
            margin-top: mobile(6);
        }
    }
}

@mixin kr() {
    .lang-kr & {
        @content;
    }
}

@mixin en() {
    .lang-en & {
        @content;
    }
}

@mixin vn() {
    .lang-vn & {
        @content;
    }
}

@mixin envn() {
    .lang-en &,
    .lang-vn & {
        @content;
    }
}

@mixin global() {
    & :global {
        @content;
    }
}
