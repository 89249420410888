@import "styles/utils";

.messenger-main-give-points-payment-detail {
    border-top: rem(1) solid $gray-5;

    @include media("<mobile") {
        border-top-width: mobile(2);
    }

    .payment-item,
    .payment-sub-item {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .payment-item {
        align-items: center;
        padding: rem(18) 0;
        border-bottom: rem(1) solid $gray-1;

        @include media("<mobile") {
            padding: mobile(32) 0;
            border-bottom-width: mobile(1);
        }

        &:not(.active) {
            .payment-sub-item {
                display: none;
            }
        }

        &.emphasis {
            .payment-item-content {
                font-weight: $font-weight-bold;

                @include media("<mobile") {
                    font-size: mobile(32);
                }
            }
        }

        ul {
            width: 100%;
        }
    }

    .payment-item-title {
        flex-shrink: 0;
        width: rem(100);
        margin-right: rem(12);
        font-size: rem(14);
        white-space: pre-wrap;
        color: $gray-3;

        @include media("<mobile") {
            width: mobile(200);
            margin-right: mobile(24);
            font-size: mobile(28);
        }
    }

    .payment-item-content {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex: 1;
        overflow: hidden;
        font-size: rem(14);
        font-weight: $font-weight-medium;
        text-align: right;
        word-break: break-all;

        &.empty {
            color: $gray-2;
        }

        @include media("<mobile") {
            font-size: mobile(28);
            color: $gray-5;
        }

        .ellipsis-text {
            @include ellipsis;
            flex: 1;
        }
    }
}
