@import "styles/utils";

#interruption-modal {
    padding: 0;

    @include media(">=mobile") {
        width: rem(400);
    }

    h3 {
        @include modal-title;
        margin-bottom: rem(8);

        @include media("<mobile") {
            margin-bottom: mobile(24);
        }
    }

    .text-area {
        margin-bottom: rem(24);
        font-size: rem(14);
        text-align: center;
        white-space: pre-wrap;
        line-height: 1.7;
        color: $gray-3;

        @include media("<mobile") {
            margin-bottom: mobile(50);
            font-size: mobile(24);
        }
    }

    ul {
        margin-bottom: rem(30);
        padding: rem(18) rem(10);
        border-top: rem(1) solid $gray-1;
        border-bottom: rem(1) solid $gray-1;

        @include media("<mobile") {
            margin-bottom: mobile(50);
            padding: mobile(38) 0;
            border-top-width: mobile(1);
            border-bottom-width: mobile(1);
        }
    }

    .reason-item {
        display: flex;
        justify-content: space-between;
        align-items: center;

        & + .reason-item {
            margin-top: rem(8);

            @include media("<mobile") {
                margin-top: mobile(20);
            }
        }

        p {
            font-size: rem(14);
            color: $gray-3;

            @include media("<mobile") {
                font-size: mobile(28);
            }
        }

        span {
            @include media("<mobile") {
                font-size: mobile(32);
                color: $gray-5;
            }
        }
    }

    .button-area {
        .rs-button {
            width: 100%;

            & + .rs-button {
                margin-top: rem(10);

                @include media("<mobile") {
                    margin-top: mobile(16);
                }
            }
        }
    }
}
