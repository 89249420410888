@import "styles/utils";

.messenger-side-tip-banner {
    display: flex;
    flex-shrink: 0;
    padding: rem(20);
    border-bottom: rem(1) solid $gray-1;
    font-size: rem(12);
    line-height: 1.5;
    background-color: $gray-0;
    white-space: pre-wrap;
    word-break: keep-all;
    color: $gray-3;

    @include media("<mobile") {
        padding: $indent-mobile;
        border-bottom-width: mobile(1);
        font-size: mobile(24);
        line-height: 1.7;
        white-space: initial;
    }

    .rs-icon {
        margin-right: rem(6);
        line-height: 1.5;

        @include media("<mobile") {
            margin-right: mobile(10);
        }
    }
}
