/*----------------------*/
/*-----   Layout   -----*/
/*----------------------*/

$width-max: rem(1444);

$header-z-index: 1000;
$header-height: rem(130);
$header-top-height: rem(74);
$header-bottom-height: rem(56);
$header-height-mobile: mobile(111);
$header-bottom-height-mobile: mobile(100);
$bottom-tab-bar-mobile: mobile(118);
$bottom-tab-bar-with-space-mobile: mobile(140);

$indent-wide-web: rem(60);
$indent-mobile: mobile(32);

//HEIGHT
$xxsmall-height: rem(24);

$spacing-xxl: rem(210);
$spacing-xl: rem(72);
$spacing-l: rem(50);
$spacing-m: rem(34);
$spacing-s: rem(24);
$spacing-xs: rem(10);

$standard-modal-top-padding: rem(30);
$standard-modal-top-padding-mobile: mobile(40);
$standard-modal-indent: rem(30);
$standard-modal-indent-mobile: mobile(32);

$functional-modal-top-padding: rem(20);
$functional-modal-top-padding-mobile: mobile(30);
$functional-modal-indent: rem(30);
$functional-modal-indent-mobile: mobile(32);

$biz-center-top-padding: rem(32);
$biz-center-indent: rem(32);
$biz-center-indent-medium: rem(24);
$biz-center-indent-mobile: $indent-mobile;

$messenger-indent: rem(20);

// web title top margin : 50, 72
// mobile title top margin : header size + 50 = 146
// mobile content padding: 0 32
