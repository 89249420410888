@import "styles/utils";

.messenger-main-side-select-from {
    overflow-y: auto;
    height: 100%;
    padding: 0 $messenger-indent rem(24);

    @include media("<mobile") {
        padding: $indent-mobile;
    }

    .from-list {
        margin-top: rem(8);

        @include media("<mobile") {
            margin-top: mobile(10);
        }

        li {
            display: flex;
            align-items: center;
            padding: rem(10) rem(12);
            border-bottom: rem(1) solid $gray-1;

            @include media("<mobile") {
                padding: $indent-mobile mobile(10);
                border-bottom-width: mobile(1);
            }

            &.active {
                background-color: $blue-lightest;
            }
        }

        .from-name {
            flex: 1;
            font-size: rem(14);

            @include media("<mobile") {
                font-size: mobile(28);
            }
        }

        .rs-profile {
            margin-top: rem(3);
            margin-right: rem(6);

            @include media("<mobile") {
                margin-right: mobile(16);
            }
        }

        .rs-button {
            width: rem(70);
            margin-left: rem(22);

            @include media("<mobile") {
                width: mobile(124);
                margin-top: mobile(6);
                margin-left: mobile(62);
            }
        }
    }
}
