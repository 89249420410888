@import "styles/utils";

.ie-browser-banner {
    display: none;
    padding: rem(15) 0;
    font-size: rem(14);
    background-color: $red-lighter;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        display: block !important;
    }

    .ie-browser-banner-inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: $width-xl;
        height: 100%;
        margin: 0 auto;
    }

    .caution-text {
        display: flex;
        align-items: center;
        color: $red;

        p {
            margin-right: rem(14);
        }

        a {
            font-size: rem(12);
            font-weight: $font-weight-medium;
            text-decoration: underline;
            color: $gray-5;
        }

        .rs-icon {
            margin-right: rem(6);
        }
    }

    .close-btn {
        font-size: rem(12);
        font-weight: $font-weight-medium;
        color: $gray-2;
        cursor: pointer;

        .rs-icon {
            margin-left: rem(6);
            font-size: rem(12);
        }
    }
}
