@import "styles/utils";

$messenger-file-list-move-btn-width: rem(38);
$messenger-file-list-move-btn-width-mobile: $indent-mobile;
$messenger-file-list-item-margin: rem(4);
$messenger-file-list-item-margin-web: rem(2);

.messenger-main-attached-file-list {
    position: relative;
    overflow: hidden;
    margin-top: rem(18);
    padding: 0 $messenger-file-list-move-btn-width;

    @include media("<mobile") {
        margin: mobile(32) (-$indent-mobile) 0;
        padding: 0;
    }

    .prev-btn,
    .next-btn {
        @include btn-clear-style;
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 1;
        width: $messenger-file-list-move-btn-width;
        color: $gray-5;

        @include media("<mobile") {
            width: $messenger-file-list-move-btn-width-mobile;
        }

        &:disabled {
            color: $gray-2;
        }

        .rs-icon {
            @include only-web;
            padding: rem(1);
            font-size: rem(18);
        }
    }

    .prev-btn {
        left: 0;
        text-align: left;
        background-image: linear-gradient(
            to left,
            rgba($white, 0) 0%,
            rgba($white, 0.96) 49%,
            $white 100%
        );
    }

    .next-btn {
        justify-content: flex-end;
        right: 0;
        background-image: linear-gradient(
            to right,
            rgba($white, 0) 0%,
            rgba($white, 0.96) 49%,
            $white 100%
        );
    }

    .file-item-list-wrapper {
        margin: 0 (-$messenger-file-list-item-margin);
        transition: transform 0.3s;

        @include media("<wide-web", ">=mobile") {
            margin: 0 (-$messenger-file-list-item-margin-web);
        }

        @include media("<mobile") {
            transform: translateX(0) !important;
        }
    }

    ul {
        display: flex;
        transform: translateX(-$messenger-file-list-move-btn-width);
        transition: transform 0.5s;

        @include media("<mobile") {
            overflow-x: auto;
            margin: 0 $indent-mobile;
            transform: translateX(0) !important;
        }

        &.has-index {
            @include media(">=mobile") {
                transform: translateX(0);
            }

            &.end-point {
                @include media(">=mobile") {
                    transform: translateX($messenger-file-list-move-btn-width);
                }
            }
        }
    }

    li {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        width: calc(25% - (#{$messenger-file-list-item-margin} * 2));
        margin: 0 $messenger-file-list-item-margin;
        padding: rem(10) rem(12);
        border-radius: rem(6);
        background-color: $gray-0;
        white-space: nowrap;

        @include media("<wide-web", ">=mobile") {
            width: calc(25% - (#{$messenger-file-list-item-margin-web} * 2));
            margin: 0 $messenger-file-list-item-margin-web;
            padding: rem(10);
        }

        @include media("<mobile") {
            width: mobile(382);
            padding: mobile(22) mobile(24);
            border-radius: mobile(6);
        }
    }

    .file-image,
    .file-icon {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        width: rem(20);
        height: rem(20);
        margin-right: rem(4);

        @include media("<mobile") {
            width: mobile(44);
            height: rem(44);
            margin-right: mobile(16);
            font-size: mobile(30);
        }
    }

    .file-image {
        border-radius: rem(2);
        object-fit: cover;

        @include media("<mobile") {
            width: mobile(44);
            height: mobile(44);
            border-radius: mobile(4);
        }
    }

    .file-icon {
        color: $yellow-dark;
    }

    .file-name,
    .file-format {
        font-size: rem(12);

        @include media("<mobile") {
            font-size: mobile(28);
        }
    }

    .file-name {
        @include ellipsis;
    }

    .file-format {
        flex: 1;
    }

    .remove-btn {
        @include btn-clear-style;
        margin-left: rem(10);
        font-size: rem(8);
        color: $gray-2;

        @include media("<mobile") {
            margin-left: mobile(20);
            font-size: mobile(18);
        }
    }
}
