@import "styles/utils";

.wrapper {
    position: relative;
    padding: rem(10) 0;

    &:hover {
        .arrowDown {
            display: none;
        }
    }

    &:not(:hover) {
        .menuBox,
        .arrowUp {
            display: none;
        }
    }
}

.userBadge {
    display: flex;
    align-items: center;
    height: rem(54);
    padding: rem(5) rem(6) rem(5) rem(16);
    border: rem(1) solid $gray-1;
    border-radius: rem(30);
    font-size: rem(14);
    font-weight: $font-weight-medium;
    box-shadow: 0 0 6px 0 rgba(230, 232, 237, 0.8);
    cursor: pointer;

    .profile {
        margin-left: rem(8);
    }

    .arrowIcon {
        margin: 0 rem(6);
        font-size: rem(12);
        color: $gray-2;
    }
}

.userArea {
    display: flex;
    align-items: center;
    margin-bottom: rem(24);

    .profile {
        margin-right: rem(8);
    }

    .profile ~ .userText {
        .nameTitle {
            width: 100%;
        }

        .userActions {
            margin-top: rem(8);
        }
    }
}

.userText {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
}

.pointsBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: rem(10);
    padding: 0 rem(15);
    border: rem(1) solid $gray-1;
    border-radius: rem(4);
    font-size: rem(14);
    background-color: $gray-0;

    @include global {
        .rs-button {
            padding-left: rem(20);
            padding-right: rem(20);
        }
    }
}

.pointsTitle {
    margin-right: rem(8);
    color: $gray-3;
}

.pointsAmount {
    display: flex;
    align-items: center;
    font-weight: $font-weight-bold;

    @include global {
        .rs-icon {
            margin-top: rem(1);
            margin-left: rem(2);
            font-size: rem(10);
        }
    }
}

.chargeLink {
    order: 1;

    & ~ .pointsLink {
        justify-content: flex-start;
    }
}

.pointsLink {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    height: $field-height-l;
}

.menuBox {
    position: absolute;
    z-index: 1;
    top: 100%;
    right: 0;
    width: rem(390);
    margin-top: rem(-6);
    padding: rem(28) rem(20);
    border-radius: rem(4);
    background-color: $white;
    box-shadow: 0 0 rem(6) 0 rgba($black, 0.06);
}

.menuList {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    border: rem(1) solid $gray-1;
    border-radius: rem(4);
}

.menuItem {
    flex: 1;
    min-width: 33.3333%;
    font-size: rem(14);
    text-align: center;
    margin: rem(-1);

    a {
        display: flex;
        justify-content: center;
        align-items: center;
        height: $field-height-l;
        border: rem(1) solid $gray-1;
        background-color: $white;
    }
}

.nameTitle {
    .userName {
        font-weight: $font-weight-medium;
    }
}

.userActions {
    display: flex;
    align-items: center;
    margin: 0 rem(-2);

    li {
        margin: 0 rem(2);
    }

    @include global {
        .rs-button {
            padding-left: rem(6);
            padding-right: rem(6);
        }
    }
}

.newMark {
    margin-left: rem(2);
    color: $orange-dark;
}

.promotionBubble {
    border-color: $orange-dark;
    background-color: $orange-dark;
}
