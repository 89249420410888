@import "styles/utils";

.messenger-message-item {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    align-items: flex-start;

    &.has-next {
        .send-at {
            display: none;
        }
    }

    &.is-next {
        margin-top: rem(6);

        @include media("<mobile") {
            margin-top: mobile(12);
        }

        .user-name {
            display: none;
        }
    }

    &.mine {
        align-items: flex-end;

        .user-name {
            text-align: right;
        }

        .message-speech-bubble {
            order: 1;

            &.text {
                background-color: #bed8ff;
            }
        }
    }

    &.date {
        align-items: center;
        margin: rem(22) 0;

        @include media("<mobile") {
            margin: mobile(42) 0;
        }

        &:first-child {
            @include media(">=mobile") {
                margin-top: 0;
            }
        }

        .message-sub-info {
            display: none;
        }

        .message-item-content {
            padding: rem(3) rem(20);
            font-size: rem(10);
            border-radius: rem(60);
            background-color: $gray-1;
            color: $gray-3;

            @include media("<mobile") {
                padding: mobile(12) mobile(30);
                font-size: mobile(22);
                border-radius: mobile(40);
            }
        }
    }

    &:not(.date) + .messenger-message-item:not(.is-next):not(.date) {
        margin-top: rem(16);

        @include media("<mobile") {
            margin-top: mobile(12);
        }
    }

    &.mine + .messenger-message-item.other,
    &.other + .messenger-message-item.mine {
        @include media("<mobile") {
            margin-top: mobile(42) !important;
        }
    }

    p {
        @include media(">=mobile") {
            line-height: 1.5;
        }
    }

    .user-name {
        @include only-web;
        display: flex;
        align-items: center;
        margin-bottom: rem(5);
        font-size: rem(12);
        color: $gray-3;

        .user-name-text {
            @include ellipsis;
            max-width: rem(160);
        }

        .rs-icon {
            margin-right: rem(4);
        }
    }

    .message-content {
        display: flex;
        flex-shrink: 0;
    }

    .message-info {
        display: flex;
    }

    .message-sub-info {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin: 0 rem(10);

        @include media("<mobile") {
            margin: 0 mobile(8);
        }

        .download-area {
            display: flex;
            align-items: center;
            flex: 1;
        }
    }

    .rs-profile {
        margin-right: rem(8);

        @include media("<mobile") {
            margin-right: mobile(12);
        }
    }

    .message-speech-bubble {
        flex-shrink: 0;
        position: relative;
        overflow: hidden;
        max-width: rem(290);
        border-radius: rem(16);
        background-color: $white;

        @include media("<mobile") {
            max-width: mobile(430);
            border-radius: mobile(28);
        }
    }

    .image-info-box {
        display: flex;
        align-items: flex-end;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: rem(20) rem(12);
        font-size: rem(13);
        background-image: linear-gradient(to bottom, rgba($black, 0), rgba($black, 0.7));
        color: $white;
        transition: opacity 0.1s;

        @include media("<mobile") {
            padding: mobile(32) mobile(24);
            font-size: mobile(22);
        }
    }

    .file-name {
        @include ellipsis;
    }

    .file-format {
        flex-shrink: 0;
    }

    .download-btn {
        width: rem(32);
        border-radius: 50%;

        @include media("<mobile") {
            width: mobile(58);
        }
    }

    .send-at {
        font-size: rem(12);
        color: $gray-2;

        @include media("<mobile") {
            margin: 0 mobile(8);
            font-size: mobile(20);
        }
    }
}
