@import "styles/utils";

#create-feed-modal {
    @include media(">=mobile") {
        width: rem(440);
    }

    &.fetching {
        height: rem(490);

        @include media("<mobile") {
            height: mobile(838);
        }

        .rs-standard-modal-scroll-area {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .rs-segmented {
        margin-bottom: rem(20);

        @include media("<mobile") {
            margin-bottom: mobile(44);
        }
    }

    .rs-dropdown {
        width: 100%;
        margin-top: rem(8);

        @include media("<mobile") {
            margin-top: mobile(16);
        }

        .rs-dropdown-list {
            max-height: rem(160);
        }
    }

    .rs-caution-area {
        padding: rem(20) 0 rem(30);
        border-bottom: rem(1) solid $gray-1;

        @include media("<mobile") {
            padding: mobile(30) 0 mobile(44);
        }
    }

    .rs-message-box {
        font-size: rem(14);

        @include media("<mobile") {
            font-size: mobile(28);
        }
    }

    .upload-multi-file-box {
        @include media("<mobile") {
            margin-bottom: mobile(20);
        }
    }

    .change-hide-status-area {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: rem(30);

        @include media("<mobile") {
            padding-top: mobile(48);
        }
    }
}
