@import "styles/utils";

.common-banner-item {
    display: block;
    width: 100%;
    height: rem(72);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    @include media("<mobile") {
        height: mobile(160);
    }

    &.mainTop {
        height: rem(100);
        margin-bottom: rem(70);
        border-radius: rem(10);

        @include media("<wide-web", ">=mobile") {
            margin-bottom: rem(60);
        }

        @include media("<mobile") {
            height: mobile(180);
            margin-bottom: mobile(80);
            border-radius: mobile(10);
        }
    }

    &.reviewerMypage {
        height: rem(80);
        border-radius: rem(10);

        @include media("<mobile") {
            height: mobile(194);
            border-radius: mobile(20);
        }
    }

    &.talentList,
    &.portfolioList,
    &.bizIntro {
        background-size: contain;
    }
}
