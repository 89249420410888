@import "styles/utils";

.wrapper {
    @include media("<mobile") {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    > * {
        @include media("<mobile") {
            flex-shrink: 0;
        }
    }
}

.content {
    @include media("<mobile") {
        flex: 1;
    }
}

.textarea {
    width: 100%;
    height: rem(440);
    margin-bottom: rem(12);

    @include media("<mobile") {
        height: 100%;
        margin-bottom: mobile(24);
    }
}
