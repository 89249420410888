@import "styles/utils";

.common-loading {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9001;

    &.active {
        bottom: 0;
    }

    &:not(.active) {
        visibility: hidden;
    }

    &.black {
        background-color: rgba(0, 0, 0, 0.7);
    }

    .progress-bar {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: rem(4);
        background-color: $gray-2;

        @include media("<mobile") {
            height: mobile(4);
        }
    }

    .progress {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        border-top-right-radius: rem(10);
        border-bottom-right-radius: rem(10);
        background-image: linear-gradient(to right, $active-color, $tertiary-color);
        box-sizing: content-box;
        transition: width 0.3s;

        @include media("<mobile") {
            border-top-right-radius: mobile(20);
            border-bottom-right-radius: mobile(20);
        }
    }
}
