@import "styles/utils";

.suggestCompleteModal {
    text-align: center;

    @include media(">=mobile") {
        width: rem(600);
    }
}

.modalInner {
    padding-top: rem(20);
    padding-bottom: rem(10);

    @include media("<mobile") {
        padding-top: mobile(10);
        padding-bottom: mobile(10);
    }
}

.successMsg {
    font-size: rem(20);
    font-weight: $font-weight-medium;
    white-space: pre-wrap;
    color: $gray-5;

    @include media("<mobile") {
        font-size: mobile(36);
    }

    span {
        box-shadow: inset 0 rem(-12) 0 0 $yellow;
    }
}

.desc {
    margin-top: rem(10);
    font-size: rem(14);
    color: $gray-2;

    @include media("<mobile") {
        margin-top: mobile(16);
        font-size: mobile(26);
        white-space: pre-wrap;
        line-height: 1.5;
    }
}

.buttonItems {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: rem(24);

    @include media("<mobile") {
        margin-top: mobile(40);
    }
}

.buttonItem {
    width: rem(200);

    @include media("<mobile") {
        width: mobile(290);
    }

    & + .buttonItem {
        margin-left: rem(8);

        @include media("<mobile") {
            margin-left: mobile(12);
        }
    }
}

.cautionList {
    margin-top: rem(30);
    padding-top: rem(30);
    border-top: rem(1) solid $gray-1;
}
