@import "styles/utils";

.messenger-main-give-points {
    display: flex;
    flex-direction: column;
    height: 100%;

    > * {
        flex-shrink: 0;
    }

    .scroll-area {
        flex: 1;
        overflow-y: auto;
        padding: 0 $messenger-indent rem(20);
    }

    .total-points {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 (-$messenger-indent);
        padding: rem(16) $messenger-indent;
        font-size: rem(14);
        background-color: $blue-lighter;
        color: $main-color;

        @include media("<mobile") {
            padding: $indent-mobile;
            font-size: mobile(28);
        }

        span {
            font-weight: $font-weight-bold;

            @include media("<mobile") {
                font-size: mobile(32);
            }
        }
    }

    .input-area {
        margin: rem(20) 0 rem(28);

        @include media("<mobile") {
            margin: mobile(40) 0;
        }

        .caution-message {
            margin-top: rem(6);
            font-size: rem(12);
            color: $gray-2;

            @include media("<mobile") {
                margin-top: mobile(12);
                font-size: mobile(24);
            }

            & + .caution-message {
                margin-top: rem(4);

                @include media("<mobile") {
                    margin-top: mobile(10);
                }
            }
        }

        a {
            @include link-style;
        }

        input {
            text-align: right;
        }
    }

    .rs-caution-area {
        margin-top: rem(28);

        @include media("<mobile") {
            margin-top: mobile(40);
        }

        .rs-caution-text {
            color: $gray-2;
        }
    }

    .give-points-btn {
        margin: rem(18) $messenger-indent;

        @include media("<mobile") {
            margin: $indent-mobile;
        }
    }
}
