@import "styles/utils";

#messenger-main-content {
    display: flex;
    flex: 1;
    overflow: hidden;
    background-color: $gray-0;

    @include media("<mobile") {
        flex: none;
        width: 100%;
        transition: transform 0.3s;
    }

    &.active {
        @include media("<mobile") {
            transform: translateX(-50%);
        }
    }

    .messenger-main-content-wrapper {
        display: flex;
        flex-direction: column;
        flex: 1;
        width: rem(400);
        height: 100%;

        @include media("<mobile") {
            flex: none;
            width: 50%;
        }

        .empty-content {
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 1;
            font-size: rem(14);

            @include media("<mobile") {
                font-size: mobile(28);
            }
        }
    }
}
