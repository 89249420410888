@import "styles/utils";

.messenger-message-box-item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: $messenger-indent;
    border-bottom: rem(1) solid $gray-0;
    cursor: pointer;

    @include media("<mobile") {
        padding: mobile(40) $indent-mobile;
        border-bottom-width: mobile(1);
        border-bottom-color: $gray-1;
    }

    &.active {
        background-color: $blue-lightest;
    }

    &.disabled {
        .text-area,
        .message-area .message {
            color: $gray-2;
        }
    }

    &.reviewer {
        .message-area .message {
            max-width: rem(220);

            @include media("<mobile") {
                max-width: mobile(480);
            }
        }
    }

    .rs-profile {
        margin-right: rem(14);

        @include media("<mobile") {
            margin-right: mobile(32);
        }
    }

    .text-area {
        flex: 1;
        max-width: 100%;

        .info-area {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .other-name {
            @include ellipsis;
            padding-right: rem(4);
            font-size: rem(14);
            font-weight: $font-weight-medium;

            @include media("<mobile") {
                padding-right: mobile(6);
                font-size: mobile(30);
                color: $gray-5;
            }
        }

        .date {
            flex-shrink: 0;
            font-size: rem(12);
            color: $gray-2;

            @include media("<mobile") {
                font-size: mobile(22);
            }
        }
    }

    .message-area {
        display: flex;
        justify-content: space-between;
        margin-top: rem(3);

        @include media("<mobile") {
            margin-top: mobile(6);
        }

        .message {
            @include multi-line-overflow(2);
            max-width: rem(180);
            font-size: rem(13);
            color: $gray-3;

            @include media("<wide-web", ">=mobile") {
                max-width: rem(160);
            }

            @include media("<mobile") {
                max-width: mobile(370);
                font-size: mobile(26);
            }
        }

        .new-mark {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
            width: rem(16);
            height: rem(16);
            // margin-top: rem(2);
            padding-bottom: rem(1);
            border-radius: 50%;
            font-size: rem(8);
            font-weight: $font-weight-medium;
            background-color: $orange-dark;
            color: $white;

            @include media("<mobile") {
                width: mobile(36);
                height: mobile(36);
                margin-top: mobile(1);
                padding-bottom: mobile(1);
                font-size: mobile(22);
            }
        }
    }

    .brand-area {
        display: flex;
        align-items: center;
        width: 100%;
        margin-top: rem(12);
        padding: rem(5) rem(12);
        border: rem(1) solid $blue-lighter;
        border-radius: rem(6);
        font-size: rem(12);
        background-color: $blue-lightest;

        @include media("<mobile") {
            margin-top: mobile(28);
            padding: mobile(18) $indent-mobile;
            border-width: mobile(1);
            border-radius: mobile(20);
            font-size: mobile(26);
            color: $gray-5;
        }

        span {
            display: flex;
            align-items: center;
            flex-shrink: 0;
            color: $gray-2;

            &:after {
                content: "";
                display: block;
                width: rem(1);
                height: rem(11);
                margin: rem(1) rem(6) 0;
                background-color: $blue-lighter;

                @include media("<mobile") {
                    width: mobile(2);
                    height: mobile(24);
                    margin: 0 mobile(20);
                }
            }
        }

        p {
            @include ellipsis;
        }
    }
}
