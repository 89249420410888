@import "styles/utils";

.bottom-tab-bar-custom {
    display: flex;
    flex: 1;
    margin: 0 mobile(-6);
    padding: 0 $indent-mobile;

    .rs-button {
        width: 100%;
    }

    > * {
        flex: 1;
        margin: 0 mobile(6);
    }
}
