@import "styles/utils";

#messenger {
    transition: transform 0.3s;

    @include media(">=mobile") {
        transform: scale(0);
    }

    @include media("<mobile") {
        width: 100%;
        height: 100%;
        transform: translateY(100%);
    }

    &.active {
        @include media(">=mobile") {
            transform: scale(1);
        }

        @include media("<mobile") {
            margin-top: 0;
            transform: translateY(0);
        }
    }

    .messenger-template {
        display: flex;
        overflow: hidden;
        width: rem(1224);
        height: rem(690);
        border-radius: rem(12);
        background-color: $white;

        @include media("<wide-web", ">=mobile") {
            width: rem(1080);
        }

        @media screen and (min-width: 768px) and (min-height: 860px) {
            height: rem(770);
        }

        @include media("<mobile") {
            width: 200%;
            height: 100%;
            border-radius: 0;
        }
    }

    .messenger-close-btn {
        @include only-web;
        position: absolute;
        right: 0;
        bottom: 100%;
        width: rem(32);
        height: rem(32);
        margin-bottom: rem(12);
        border: none;
        border-radius: 50%;
        font-size: rem(11);
        background-color: $black;
        color: $white;
    }
}
