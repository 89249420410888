@import "styles/utils";

.dateBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: rem(13);
    border: rem(1) solid $gray-1;
    border-radius: rem(4);

    @include media("<mobile") {
        margin-top: mobile(-20);
        padding: mobile(24);
        border-width: mobile(1);
        border-radius: mobile(6);
    }

    @include global {
        .react-date-picker {
            position: absolute !important;
            top: 100%;
            left: 0;
            right: 0;
        }

        .react-date-picker__calendar {
            @include media(">=mobile") {
                inset: rem(4) 0 auto auto !important;
            }

            @include media("<mobile") {
                inset: mobile(14) 0 auto auto !important;
            }
        }
    }
}

.dateItem {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    font-size: rem(14);
    font-weight: $font-weight-medium;

    @include media("<mobile") {
        font-size: mobile(26);
    }

    &.disabled {
        color: $gray-2;
    }

    @include global {
        .rs-icon {
            margin-right: rem(6);
            font-size: rem(16);

            @include media("<mobile") {
                margin-right: mobile(10);
                font-size: mobile(24);
            }
        }
    }

    .changeBtn {
        margin-left: rem(8);
        padding-left: rem(10);
        padding-right: rem(10);

        @include media("<mobile") {
            margin-left: mobile(12);
            padding-left: mobile(24);
            padding-right: mobile(24);
        }

        &:disabled {
            display: none;
        }
    }
}
